import React, { useState, useEffect } from 'react'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'

import { StyledQtyBtn } from '@core'
import { InputCom } from '../form'
import { ButtonCom } from './button'

export const QtyBtnCom = ({ onChange, ...props }) => {
  const [qty, setQty] = useState(1)

  useEffect(() => {
    onChange(qty)
  }, [qty])

  const handleQty = (key) => () => {
    if (key === 'add') {
      let num = qty
      num += 1
      setQty(num)
    } else {
      if (qty > 1) {
        let num = qty
        num -= 1
        setQty(num)
      }
    }
  }

  return (
    <StyledQtyBtn {...props}>
      <ButtonCom
        className='btn sub'
        SVGL={<AiOutlineMinus />}
        onClick={handleQty('sub')}
      />
      <InputCom
        type='number'
        value={qty}
        onChange={e => setQty(e.target.value ? +e.target.value : 1)}
        placeholder='1'
      />
      <ButtonCom
        className='btn add'
        SVGL={<AiOutlinePlus />}
        onClick={handleQty('add')}
      />
    </StyledQtyBtn>
  )
}

QtyBtnCom.defaultProps = {

}