import React from 'react'

import { StyledRatingContainer } from '@core'

export const RatingCom = ({ count }) => {

  // if (count == 0) { return null }

  let stars = []

  for (let i = 1; i <= 5; i++) {
    if (i <= count) {
      stars.push(require('@asset/icon/full-star.svg'))
    } else if (i <= Math.ceil(count)) {
      stars.push(require('@asset/icon/half-star.svg'))
    } else {
      stars.push(require('@asset/icon/empty-star.svg'))
    }
  }

  return (
    <StyledRatingContainer>
      {
        stars?.map((x, i) => (
          <img className='star-img' src={x.default} alt='star' key={i} />
        ))
      }
    </StyledRatingContainer>
  )
}