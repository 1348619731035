import styled from 'styled-components'

export const StyledText = styled.p`
  line-height: ${props => props.theme.fontSize.md * 1.3}px;
  font-size: ${props => props.theme.fontSize[props?.size] || props.theme.fontSize.md}px;
  font-weight: ${props => props.theme.fontWeight[props?.weight]};
  color: ${props => props.theme.color[props?.color] || props.color};
  letter-spacing: ${props => props.theme.fontSpacing(props.theme.fontSize[props?.size] || props.theme.fontSize?.md)}px;

  &.del{
    text-decoration: line-through;
  }
`