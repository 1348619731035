import React from 'react'

import { StyledInfoContainer } from '@core'
import { CardCom } from '../card'
import { TextCom } from '../typo'
import { RowCom } from '../grid'
import { ColCom } from '../grid'
import { ImageCom } from '../image'

export const AboutUsCom = ({ data, props }) => {

  return (
    <CardCom>
      <StyledInfoContainer {...props}>
        <RowCom>
          <ColCom lg='6' md='6' className='first-col'>
            <div className='img-wrap'>
              {data?.image && <ImageCom
                width={445}
                height={333}
                img={data?.image}
              />}
            </div>
            {/* <TextCom size='xxxl' weight='xxxl'>About Us</TextCom>
            <TextCom>{data?.address}</TextCom>
            <TextCom>{data?.phone}</TextCom>
            <TextCom>{data?.email}</TextCom> */}
          </ColCom>
          <ColCom lg='6' md='6' className='sec-col'>
            <TextCom size='xxxl' weight='xxxl'>About Us</TextCom>
            <TextCom>{data?.address}</TextCom>
            <TextCom>{data?.phone}</TextCom>
            <TextCom>{data?.email}</TextCom>
          </ColCom>
        </RowCom>
      </StyledInfoContainer>
    </CardCom>
  )
}