import React from 'react'
import { Formik } from 'formik'

import { StyledAuthContainer } from '@core'
import { CardCom } from '../card'
import { TextCom } from '../typo'

export const ForgetPassCom = ({ handleSubmit, initialValues, validationSchema, children, props }) => {

  const onDataSubmit = (values, actions) => {
    setTimeout(async () => {
      await handleSubmit(values)
      actions.resetForm(initialValues)
      actions.setSubmitting(false)
    }, 1000)
  }

  return (
    <CardCom className='auth-card'>
      <StyledAuthContainer {...props}>
        <TextCom size='xxxl' weight='xxxl'>Forget Password?</TextCom>
        <TextCom>Enter the phone number associated with your account</TextCom>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => onDataSubmit(values, actions)}
        >
          {formikProps => children(formikProps)}
        </Formik>
      </StyledAuthContainer>
    </CardCom>
  )
}