import { rgba } from 'polished'
import styled from 'styled-components'

export const StyledSideBarFilter = styled.div`
  .cont{
    padding: 20px;
    margin-bottom: 20px;

    .filter-title{
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 15px;

      &:before {
        content: '';
        width: 40px;
        height: 2px;
        background-color: ${props => props.theme.color.primary};
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: ${props => props.theme.color.grey};
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .price-filter-wrap{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  
      input{
        height: 38px;
        margin-right: 5px;
        padding: 5px;
      }
    
      button{
        min-width: 50px;
        margin: 0
      }
    }

    ul{
      padding-left: 0;
      margin-bottom: 0;
      li{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-bottom: 1px dashed ${({ theme }) => theme.color.grey};
        padding: 10px;
        transition: background-color .2s ease;
        border-radius: 10px;
        &:hover,
        &.active {
         background-color: ${({ theme }) => rgba(theme.color.secondary, .2)};
         svg{
          color: ${({ theme }) => theme.color.secondary}
         }
         span{
            color: ${({ theme }) => theme.color.secondary};
          }
        }

        &:hover{
          .sub-drp {
           opacity: 1;
           visibility: visible;
         }
        }

        .sub-drp {
          min-width: 210px;
          position: absolute;
          top: 45px;
          left: initial;
          right: 0;
          padding-left: 10px;
          opacity: 0;
          visibility: hidden;
          transition: opacity .3s ease;
          z-index: 9;
          background-color: ${props => props.bgColor || props.theme.color.card};
          border-radius: 10px;
          box-shadow: 0px 0px 10px ${({ theme }) => rgba(theme.color.secondary, .2)};
          padding: 5px;

          li {
            border-radius: 5px;
            span{
              color: ${({ theme }) => theme.color.text};
            }
            &:hover,
            &.active {
              background-color: ${({ theme }) => rgba(theme.color.secondary, .2)};
              span{
                color: ${({ theme }) => theme.color.secondary};
              }
              svg{
                color: ${({ theme }) => theme.color.secondary}
              }
            }
          }
          
          @media screen and (min-width: ${({ theme }) => theme.media.md}px) {
            top: 0;
            right: initial;
            left: 100%;
          }
        }
      }
    }
  }
`