import React from 'react'
import { StyledTable } from '@core'

import { TableHeaderCom } from './header'
import { TableBodyCom } from './body'
import { TableFootCom } from './foot'

export const TableCom = props => {

  return (
    <StyledTable>
      <TableHeaderCom {...props} />
      <TableBodyCom {...props} />
      <TableFootCom {...props} />
    </StyledTable>
  )
}