import React from 'react'

import { StyledLabel } from '@core'
import { FieldWrapper } from './filewrapper'
import { InputCom } from './input'

export const CheckBoxCom = ({ label, ...props }) => {

  return (
    <FieldWrapper className='custom-check' type='checkbox'>
      <InputCom
        {...props}
      />
      <StyledLabel htmlFor={props.id}>
        <span>
          {label}
        </span>
      </StyledLabel>
    </FieldWrapper>
  )
}