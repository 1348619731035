import React from 'react'
import { useField } from 'formik'

import { FieldWrapper } from './fieldwrapper'
import { InputCom } from '../form'

export const VTextArea = ({rows_height, ...props}) => {
  const [field, meta] = useField(props)

  return (
    <FieldWrapper meta={meta} {...props}>
      <InputCom
        as="textarea"
        className={`${meta.touched && meta.error ? 'error' : ''}`}
        rows={rows_height}
        textarea
        {...field}
        {...props}
      />
    </FieldWrapper>
  )
}