import { StyledSpinner, StyledPageLoading } from '@core'

export const LoadingCom = props => {
  const { text, type, loadingImg } = props
  return (
    <>
      {
        type === 'page' &&
        <StyledPageLoading>
          <img src={loadingImg} alt='page loading' />
          {text && <p>{text}</p>}
        </StyledPageLoading>
      }
      {
        type === 'app' &&
        <StyledSpinner {...props}>
          {text && <span>{text}</span>}
        </StyledSpinner>
      }
    </>
  )
}

LoadingCom.defaultProps = {
  type: 'page'
}