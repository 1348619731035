import React, { useEffect, useRef } from 'react'

import { StyledRating } from '@core'

export const StarRatingInput = props => {
  const { currentRating } = props
  const ref = useRef(null)

  useEffect(() => {
    setRating()
  })

  const hoverHandler = e => {
    const stars = e.target.parentElement.getElementsByClassName('star')
    const hoverValue = e.target.dataset.value
    Array.from(stars).forEach((star) => {
      star.style.color = hoverValue >= star.dataset.value ? '#FFC107' : '#ABABAB'
    })
  }

  const setRating = () => {
    if (ref.current) {
      const stars = ref.current.getElementsByClassName('star')
      Array.from(stars).forEach((star) => {
        star.style.color =
          currentRating >= star.dataset.value ? '#FFC107' : '#ABABAB'
      })
    }
  }

  const starClickHandler = e => {
    let rating = e.target.dataset.value
    if (props.onClick) {
      props.onClick(+(rating - currentRating) === 0 ? (rating - 1).toString() : rating)
    }
  }

  return (
    <div
      className='rating'
      ref={ref}
      data-rating={currentRating}
      onMouseOut={setRating}
    >
      {[...Array(+props.numberOfStars).keys()].map((n) => {
        return (
          <StyledRating
            className='star'
            key={n + 1}
            data-value={n + 1}
            onMouseOver={hoverHandler}
            onClick={starClickHandler}
          >
            &#9733;
          </StyledRating>
        )
      })}
    </div>
  )
}