export const actionTypes = {
  SWITCH_THEME: 'SWITCH_THEME',
}

const switchTheme = payload => {
  return ({
    type: actionTypes.SWITCH_THEME,
    payload
  })
}

export const theme = {
  switchTheme
}