import { rgba } from 'polished'
import styled from 'styled-components'

export const StyledMarkDown = styled.div`
  table{
    width: 100%;
    border: ${({ theme }) => theme.inputGeneral.border} ${({ theme }) => theme.color.light};
    border-collapse: collapse;
    border-radius: ${({ theme }) => theme.inputGeneral.borderRadius}px;
    border-style: hidden;
    box-shadow: 1px 1px 10px ${props => rgba(props.theme.color.dark, 0.15)};
    background-color: ${props => props.bgColor || props.theme.color.card};
    overflow: hidden;

    tr{
      border-bottom: ${({ theme }) => theme.inputGeneral.border} ${({ theme }) => theme.color.grey};
    }

    th{
      padding: 15px 15px;
      background-color: ${({ theme }) => theme.color.bgColor}
    }

    td{
      padding: 10px 15px;
    }
  }
`