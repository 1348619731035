import styled from 'styled-components'

export const StyledPageLoading = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
  }
  p {
    font-size: 12px;
  }
`

export const StyledSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    margin: 0 auto;
  }

  span {
    margin-left: 5px;
    color: rgba(255, 255, 255, .97);
  }

  @keyframes spinner { // spinner
    from {
      transform: rotate(0deg);
    } to {
      transform: rotate(360deg);
    }
  }
`