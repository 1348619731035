import { actionTypes } from '../actions/theme.action'
import * as theme from '@core'

let darkTheme = { ...theme, color: theme.darkcolor }
let lightTheme = { ...theme, color: theme.lightcolor }
// let overseaTheme = { ...theme, color: theme.overseacolor }

let loc = localStorage.getItem('store_theme')

const initialState = {
  baseTheme: loc === 'dark' ? darkTheme : loc === 'light' ? lightTheme : lightTheme
}

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes?.SWITCH_THEME:
      localStorage.setItem('store_theme', action.payload)
      return {
        ...state,
        baseTheme: action.payload === 'dark' ? darkTheme : action.payload === 'light' ? lightTheme : lightTheme
      }
    default:
      return state
  }
}

export default themeReducer