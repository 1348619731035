import { rgba } from 'polished'
import styled from 'styled-components'

export const StyledImageContainer = styled.div`
  background: url("${props => props.img && props.img}") no-repeat center / cover;
  position: relative;

  .inner-cont{
    background-color: ${({ theme }) => rgba(theme.color.dark, .5)};
    position: absolute;
    top: 100px;
    left: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    max-width: 500px;
  }
`

export const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background: url("${props => props.data && props.data}") no-repeat center / cover;
`