import styled, { css } from 'styled-components'
import { rgba } from 'polished'

const StyledInputGroup = styled.div`
  position: relative;
  margin-bottom: 25px;
  .ph-wrap {
    position: relative;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .select-date-field {
    > * {
      display: flex;
    }
    border: 1px solid ${({ theme }) => theme.color.primary};
    &.has-error {
      border: 1px solid ${({ theme }) => theme.color.danger};

      select{
        border-top: 0;
        border-left: 0;
        border-bottom: 0;
        border-right: 1px solid ${({ theme }) => theme.color.danger};

        &:last-child{
          border-right: 0
        }
      }

      p {
        position: absolute;
        color: ${({ theme }) => theme.color.danger};
        font-size: ${({ theme }) => theme.fontSize.sm}px;
        display: inline-block;
        margin: 0;
        left: 16px;
        line-height: 20px;
      }
    }

    &.error{
      border: 1px solid ${({ theme }) => theme.color.danger};

      select{
        border-top: 0;
        border-left: 0;
        border-bottom: 0;
        border-right: 1px solid ${({ theme }) => theme.color.danger};

        &:last-child{
          border-right: 0
        }
      }

      p {
        display: none;
      }
    }

    select {
      display: block;
      width: 100%;
      height: 40px;
      padding: ${({ theme }) => theme.inputGeneral.padding};
      font-size: ${({ theme }) => theme.fontSize.md}px;
      font-weight: ${({ theme }) => theme.fontWeight.md};
      line-height: 1.5;
      /* color: ${({ theme }) => theme.color.grey}; */
      background-color: ${({ theme }) => theme.color.light};
      background-clip: padding-box;
      /* border: ${({ theme }) => theme.inputGeneral.border} ${({ theme }) => theme.color.primary}; */
      transition: ${({ theme }) => theme.inputGeneral.transition};
      outline: 0;
      margin: 0;
      border: 0;

      border-right: 1px solid ${({ theme }) => theme.color.primary};

      &:last-child{
        border-right: 0;
      }

      /* &:first-child{
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
      }

      &:last-child{
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
      } */
    }
  }
  .react-datepicker-popper {
    z-index: 9;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .datepicker {
      display: block;
      width: 100%;
      height: 40px;
      padding: ${({ theme }) => theme.inputGeneral.padding};
      font-size: ${({ theme }) => theme.fontSize.md}px;
      font-weight: ${({ theme }) => theme.fontWeight.md};
      line-height: 1.5;
      color: ${({ theme }) => theme.color.grey};
      background-color: ${({ theme }) => theme.color.light};
      background-clip: padding-box;
      border: ${({ theme }) => theme.inputGeneral.border} ${({ theme }) => theme.color.secondary};
      transition: ${({ theme }) => theme.inputGeneral.transition};
      outline: 0;
      margin-top: 3px;
      &:disabled {
        background: ${({ theme }) => theme.color.grey};
      }
      &.error {
        border: 1px solid ${({ theme }) => theme.color.secondary};
      }
      
      &:focus {
        border-color: ${({ theme }) => theme.color.grey}
      }

      &::placeholder {
        opacity: .7;
        font-weight: 400;
      }
    }
  }
  &.custom-check {
    display: flex;
    align-items: center;
    input {
      opacity: 0;
      position: absolute;

      &[type='checkbox'] {
        &:checked {
          +label {
            &:before {
              background-color: ${({ theme }) => theme.color.light};
            }
            &:after {
              opacity: 1;
            }
          }
        }

        &:not(:checked) {
          +label {
            &:before {
              background-color: ${({ theme }) => theme.color.light};
            }
            &:after {
              opacity: 0;
            }
          }
        }
      }
    }

    label {
      ${({ theme }) => theme.inputGeneral.checkLabel}
      

      &.error {
        &:before {
          border: 1px solid ${({ theme }) => theme.color.danger};
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        border: 1px solid ${({ theme }) => theme.color.primary};
        border-radius: 0;
        width: 15px;
        height: 15px;
        border-radius: 2px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0px;
        bottom: 2px;
        margin: auto;
        left: 5px;
        width: 5px;
        height: 9px;
        border: 1px solid ${({ theme }) => theme.color.primary};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  &.custom-radio {
    display: flex;
    align-items: center;
    input {
      opacity: 0;
      position: absolute;

      &[type='radio'] {
        &:checked {
          +label {
            &:before {
              opacity: 1;
            }
          }
        }

        &:not(:checked) {
          +label {
            &:before {
              opacity: 0;
            }
          }
        }
      }
    }

    label {
      ${({ theme }) => theme.inputGeneral.checkLabel}

      &:before,
      &:after {
        content: '';
        display: block;
        border-radius: 15px;
        position: absolute;
        transition: all 0.3s ease;
      }

      &:before {
        width: 9px;
        height: 9px;
        background: ${({ theme }) => theme.color.secondary};
        top: 8px;
        left: 3px;
      }

      &:after {
        width: 15px;
        height: 15px;
        border: 1px solid ${({ theme }) => theme.color.secondary};
        left: 0;
        top: 5px;
      }
    }
  }
  svg.select-icon {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: -32px;
    margin: auto 0;
  }
`

const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.color.primary};
  border-radius: ${({ theme }) => theme.inputGeneral.borderRadius};
  padding: ${({ theme }) => theme.inputGeneral.padding};
  font-size: ${({ theme }) => theme.fontSize.md}px;
  font-weight: ${({ theme }) => theme.fontWeight.md};
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.bgColor};
  transition: ${({ theme }) => theme.inputGeneral.transition};
  line-height: 1.5;
  background-clip: padding-box;
  outline: 0;
  position: relative;

  &:disabled {
    background: ${({ theme }) => theme.color.grey};
  }

  &.error {
    border: 1px solid ${({ theme }) => theme.color.danger};
  }
  
  &:focus {
    border-color: ${({ theme }) => theme.color.primary};
    box-shadow: 0 0 0 0.25rem ${({theme}) => rgba(theme.color.primary, .2)};
  }

  &::placeholder {
    opacity: .7;
    font-weight: 400;
  }

  ${({ textarea }) => textarea && css`
    min-height: ${props => props.rows || 100}px;
  `}

  ${({ select }) => select && css`
    position: relative;
    appearance: none;
    opacity: .7;
  `}
`

const StyledLabel = styled.label`
  max-width: 100%;
  color: ${({ theme }) => theme.color.text};
  font-size: ${({ theme }) => theme.fontSize.md}px;
  font-weight: ${({ theme }) => theme.fontWeight.md};
  white-space: wrap;
  overflow: hidden;

  .requred-status{
    color: ${({ theme }) => theme.color.danger}
  }
`

const StyledSpan = styled.span`
  position: absolute;
  top: 18.5px;
  transform: translateY(13px);
  margin: auto 0;
  ${({ InputPhone }) => InputPhone && css`
    color: ${({ theme }) => theme.color.grey};
    font-size: ${({ theme }) => theme.fontSize.md}px;
    font-weight: ${({ theme }) => theme.fontWeight.md};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    top: 48%;
    transform: translateY(-48%);
    &:before {
      content: '+95';
    }
  `}
`

const StyledErrorMessage = styled.span`
  position: absolute;
  bottom: -20px;
  left: 16px;
  color: ${({ theme }) => theme.color.danger};
  font-size: ${({ theme }) => theme.fontSize.sm}px;
`

const StyledError = styled.p`
  color: ${({ theme }) => theme.color.danger};
  font-size: ${({ theme }) => theme.fontSize.md}px;
  margin: 0;
`

export {
  StyledInputGroup,
  StyledInput,
  StyledLabel,
  StyledSpan,
  StyledError,
  StyledErrorMessage
}
