const addChecked = (type, arr, checked_arr) => {
  if (checked_arr?.length > 0) {
    return checked_arr.map(x => {
      return arr.map(y => {
        if (x == y[type]) {
          y.checked = true
        }
      })
    })
  }
}

const checkChange = (type, checkListItem, data) => {
  if (checkListItem?.length > 0) {
    const changedCheckbox = checkListItem.find(x => x[type] === data)
    changedCheckbox.checked = !changedCheckbox.checked
    return checkListItem
  } else {
    return null
  }
}

const getCheckId = (type, data) => {
  if (data?.length > 0) {
    let temp = []
    data.map((x) => x.checked === true && temp.push(x[type]))
    return temp
  } else {
    return null
  }
}

const moneyFormat = (amount, lang = 'en') => {
  let eng_money = Number(amount).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  let tmp = { '0': '၀', 1: '၁', 2: '၂', 3: '၃', 4: '၄', 5: '၅', 6: '၆', 7: '၇', 8: '၈', 9: '၉' }
  let mm_money = eng_money.replace(/([0-9])/g, (s, key) => tmp[key] || s)
  return lang === 'en' ? eng_money : mm_money
}

const getUiTranslate = (dict, entry, lang = 'en') => {
  if (dict) {
    return dict[entry][lang]
  }
}

export {
  addChecked,
  checkChange,
  getCheckId,
  moneyFormat,
  getUiTranslate
}