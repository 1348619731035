import React from 'react'

import { StyledBreadCrumb } from '@core'

export const BreadCrumbCom = ({ data, ...props }) => {
  return (
    <StyledBreadCrumb {...props}>
      <ul>
        {
          data.length > 0 &&
          data.map((x, i) => (
            <li key={i}>
              {x?.icon}
              {x.route ? <a href={x.route}>{x.name}</a> : <span>{x.name}</span>}
            </li>
          ))
        }
      </ul>
    </StyledBreadCrumb>
  )
}

BreadCrumbCom.defaultProps = {
  data: []
}