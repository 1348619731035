import styled from 'styled-components'

export const StyledPrimaryFooter = styled.div`
  background-color: ${props => props.theme.color.bgColor};
  border-top: 1px solid ${props => props.theme.color.grey};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledSocialIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a{
    color: ${props => props.theme.color.dark};
    margin-right: 10px;

    &:last-child{
      margin-right: 0
    }
  }
`