import React from 'react'

import { StyledSecondaryHeader } from '@core'

export const SecondaryHeaderCom = props => {

  return (
    <StyledSecondaryHeader {...props} >
      {props.children}
    </StyledSecondaryHeader>
  )
}