import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineGlobal } from 'react-icons/ai'

import { otherAction } from '@actions'
import { StyledLanguageSwitch } from '@core'
import { ImageCom } from '../image'

const langData = [
  { lang: 'မြန်မာ', code: 'my', lan_icon: require('@asset/icon/my-flag.svg').default },
  { lang: 'English', code: 'en', lan_icon: require('@asset/icon/en-flag.svg').default }
]

export const LanguageSwitchCom = props => {
  const { langStore } = useSelector(state => state.other)
  const dispatch = useDispatch()
  let langCode = langStore.code

  const handleLangSwitch = x => () => {
    dispatch(otherAction.setLangStore(x))
  }

  return (
    <div>
      <AiOutlineGlobal />
      <StyledLanguageSwitch>
        <span>Language</span>
        <div className='drp-wrap'>
          <ul className='drp-ul'>
            {
              langData?.map((x, i) => (
                <li key={i} onClick={handleLangSwitch(x)}>
                  <ImageCom width={20} height={20} img={x?.lan_icon} alt={x?.lang} />
                  <span className={langCode === x.code ? 'active' : undefined}>{x?.lang}</span>
                </li>
              ))
            }
          </ul>
        </div>
      </StyledLanguageSwitch>
    </div>
  )
}