import React from 'react'
import { useField } from 'formik'

import { FieldWrapper } from './fieldwrapper'
import { InputCom } from '../form'

export const VTextInput = props => {
  const [field, meta] = useField(props)

  return (
    <FieldWrapper meta={meta} {...props}>
      <InputCom
        className={`${meta.touched && meta.error ? 'error' : ''}`}
        {...field}
        {...props}
      />
    </FieldWrapper>
  )
}