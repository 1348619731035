import { controller, routes, routeFilter } from '@controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  // saveCart
  SAVECART_REQUEST: 'SAVECART_REQUEST',
  SAVECART_ERROR: 'SAVECART_ERROR',
  SAVECART_SUCCESS: 'SAVECART_SUCCESS',

  // updateQtyInCart
  UPDATEQTYINCART_REQUEST: 'UPDATEQTYINCART_REQUEST',
  UPDATEQTYINCART_ERROR: 'UPDATEQTYINCART_ERROR',
  UPDATEQTYINCART_SUCCESS: 'UPDATEQTYINCART_SUCCESS',

  // updateCustomer
  UPDATECUSTOMER_REQUEST: 'UPDATECUSTOMER_REQUEST',
  UPDATECUSTOMER_ERROR: 'UPDATECUSTOMER_ERROR',
  UPDATECUSTOMER_SUCCESS: 'UPDATECUSTOMER_SUCCESS',

  // getCart
  GETCART_REQUEST: 'GETCART_REQUEST',
  GETCART_ERROR: 'GETCART_ERROR',
  GETCART_SUCCESS: 'GETCART_SUCCESS',

  // deleteCart
  DELETECART_REQUEST: 'DELETECART_REQUEST',
  DELETECART_ERROR: 'DELETECART_ERROR',
  DELETECART_SUCCESS: 'DELETECART_SUCCESS',

  // clearShoppingCart
  CLEARSHOPPINGCART_REQUEST: 'CLEARSHOPPINGCART_REQUEST',
  CLEARSHOPPINGCART_ERROR: 'CLEARSHOPPINGCART_ERROR',
  CLEARSHOPPINGCART_SUCCESS: 'CLEARSHOPPINGCART_SUCCESS',
}

const saveCart = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.SAVECART_REQUEST))
  return await controller(routes.saveCart, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.SAVECART_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.SAVECART_ERROR, error.message)))
}

const updateQtyInCart = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.UPDATEQTYINCART_REQUEST))
  return await controller(routes.updateQtyInCart, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.UPDATEQTYINCART_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.UPDATEQTYINCART_ERROR, error.message)))
}

const updateCustomer = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.UPDATECUSTOMER_REQUEST))
  return await controller(routes.updateCustomer, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.UPDATECUSTOMER_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.UPDATECUSTOMER_ERROR, error.message)))
}

const getCart = id => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCART_REQUEST))
  return await controller(`${routes.getCart}/${id}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETCART_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCART_ERROR, error.message)))
}

const deleteCart = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.DELETECART_REQUEST))
  return await controller(routes.deleteCart, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.DELETECART_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.DELETECART_ERROR, error.message)))
}

const clearShoppingCart = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.CLEARSHOPPINGCART_REQUEST))
  return await controller(routes.clearShoppingCart, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.CLEARSHOPPINGCART_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.CLEARSHOPPINGCART_ERROR, error.message)))
}

export const cartAction = {
  saveCart,
  updateQtyInCart,
  updateCustomer,
  getCart,
  deleteCart,
  clearShoppingCart
}