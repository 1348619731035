import { actionTypes } from '../actions/user.action'

const initialState = {
  error: null,
  isLoading: false,
  singUpData: null,
  otpVerifyData: null,
  checkUserByPhoneData: null,
  resetPasswordData: null,
  saleOrderByUserIdData: null,
  saleOrderByIdData: null,
  userProfileData: null,
  updateUserProfileData: null,
  updateProfileImgData: null
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USERSIGNUP_REQUEST: // userSignUp
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.USERSIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        singUpData: action.data
      }
    case actionTypes.USERSIGNUP_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.OTPVERIFY_REQUEST: // otpVerify
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.OTPVERIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpVerifyData: action.data
      }
    case actionTypes.OTPVERIFY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.CHECKUSERBYPHONE_REQUEST: // checkUserByPhone
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.CHECKUSERBYPHONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checkUserByPhoneData: action.data
      }
    case actionTypes.CHECKUSERBYPHONE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.RESETPASSWORD_REQUEST: // resetPassword
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.RESETPASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resetPasswordData: action.data
      }
    case actionTypes.RESETPASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.ETSALEORDERBYUSERID_REQUEST: // getSaleOrderByUserId
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.ETSALEORDERBYUSERID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saleOrderByUserIdData: action.data
      }
    case actionTypes.ETSALEORDERBYUSERID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETSALEORDERBYID_REQUEST: // getSaleOrderById
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETSALEORDERBYID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saleOrderByIdData: action.data
      }
    case actionTypes.GETSALEORDERBYID_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETUSERPROFILE_REQUEST: // getUserProfile
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETUSERPROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userProfileData: action.data
      }
    case actionTypes.GETUSERPROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.UPDATEUSERPROFILE_REQUEST: // updateUserProfile
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.UPDATEUSERPROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateUserProfileData: action.data
      }
    case actionTypes.UPDATEUSERPROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.UPDATEPROFILEIMG_REQUEST: // updateProfileImg
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.UPDATEPROFILEIMG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateProfileImgData: action.data
      }
    case actionTypes.UPDATEPROFILEIMG_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default user