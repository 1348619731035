import { actionTypes } from '../actions/auth.action'

const initialState = {
  error: null,
  isLoading: false,
  loginData: null
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGINWITHPHONEANDPASS_REQUEST: // loginWithPhoneAndPass
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.LOGINWITHPHONEANDPASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loginData: action.data
      }
    case actionTypes.LOGINWITHPHONEANDPASS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.LOGINWITHFACEBOOK_REQUEST: // loginWithFacebook
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.LOGINWITHFACEBOOK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loginData: action.data
      }
    case actionTypes.LOGINWITHFACEBOOK_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.LOGOUT_REQUEST: // logOut
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loginData: null
      }
    case actionTypes.LOGOUT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default auth