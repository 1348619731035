import React from 'react'

import { StyledButton } from '@core'

export const ButtonCom = props => {
  const { text, SVGL, SVGR } = props;
  return (
    <StyledButton {...props} >
      {
        !props.children ?
          <>
            {SVGL}
            {text}
            {SVGR}
          </> :
          props.children
      }
    </StyledButton>
  )
}

ButtonCom.defaultProps = {
  bgColor: 'primary',
  color: 'white',
  type: 'full'
}