import { actionTypes } from '../actions/wishlist.action'

const initialState = {
  error: null,
  isLoading: false,
  adduserWishListData: null,
  removeProductFromWishListData: null,
  wishListData: null,
  existWishListOrNotData: null
}

const wishList = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADDUSERWISHLIST_REQUEST: // addUserWishList
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.ADDUSERWISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adduserWishListData: action.data
      }
    case actionTypes.ADDUSERWISHLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.REMOVEPRODUCTFROMWISHLIST_RREQUEST: // removeProductFromWishList
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.REMOVEPRODUCTFROMWISHLIST_RSUCCESS:
      return {
        ...state,
        isLoading: false,
        removeProductFromWishListData: action.data
      }
    case actionTypes.REMOVEPRODUCTFROMWISHLIST_RERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETALLWISHLIST_REQUEST: // getAllWishList
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETALLWISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wishListData: action.data
      }
    case actionTypes.GETALLWISHLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.EXISTWISHLISTORNOT_REQUEST: // existWishlistOrNot
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.EXISTWISHLISTORNOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        existWishListOrNotData: action.data
      }
    case actionTypes.EXISTWISHLISTORNOT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default wishList