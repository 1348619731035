import React from 'react'

import { StyledTableFoot } from '@core'

export const TableFootCom = ({ footData, ...props }) => {

  return (
    <StyledTableFoot {...props}>
      {footData}
    </StyledTableFoot>
  )
}