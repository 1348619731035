import React from 'react'
import { useField } from 'formik'

import { StyledLabel } from '../../../core'
import { FieldWrapper } from './fieldwrapper'
import { InputCom } from '../form'

export const VCheckBox = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })

  return (
    <FieldWrapper className='custom-check' meta={meta} {...props}>
      <InputCom
        id='check'
        type='checkbox'
        {...field}
        {...props}
      />
      <StyledLabel htmlFor='check' className={`${meta.touched && meta.error ? 'error' : ''}`}>
        <span>
          {label}
          {props?.required && <span style={{ color: 'red' }}> *</span>}
        </span>
      </StyledLabel>
    </FieldWrapper>
  )
}