import React from 'react'

import { StyledErrorMessage, StyledLabel, StyledInputGroup } from '@core'

export const FieldWrapper = ({ meta, label, required, children, ...props }) => {

  return (
    <StyledInputGroup {...props}>
      {props.type !== 'checkbox' && <StyledLabel>
        {label}
        {required === true && <span className='requred-status'> *</span>}
      </StyledLabel>}
      {children}
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </StyledInputGroup>
  )
}