import React from 'react'

import { StyledSection } from '@core'

export const SectionCom = props => {
  const { children } = props
  return (
    <StyledSection {...props}>
      {children}
    </StyledSection>
  )
}