export const routes = {
  // Authentication
  loginWithPhoneAndPass: `get:api/auth/token`,
  loginWithFacebook: `post:api/auth/facebook`,
  logOut: `delete:api/auth/token`,

  // Product
  getProductList: `get:api/product`,
  getProductRatingAndReview: `get:api/product/{ID}/ratingreview`,
  addProductRatingAndReview: `post:api/product/{ID}/ratingreview`,
  getRelatedProduct: `get:api/product/category/{Cat_ID}/related/{count}`,
  getProductDetail: `get:api/product/{ID}`,
  getProductCategory: `get:api/product/categories`,
  getCountryOrigin: `get:api/product/countries`,
  getBrandList: `get:api/product/brands`,
  getProductsFromProductGroup: `get:api/group/{group_code}/products`,
  checkProductBalance: `get:api/product/check/quantity`,
  getOrderedProduct: `get:api/user/mostbuy`,
  updateProductViewCount: `post:api/proudct/{ID}/view`,
  getProductListOfPromotion: `get:api/promotion/{promo_id}/products`,
  getPromotionList: `get:api/list/promotion`,


  // Flash Sale

  // User
  userSignUp: `post:api/user/signup`,
  otpVerify: `post:api/user/verify`,
  checkUserByPhone: `post:api/exist/user`,
  resetPassword: `post:api/user/reset/password`,
  getSaleOrderByUserId: `get:api/orders/{page_no}/{per_page}`,
  getSaleOrderById: `get:api/orders/{order_id}`,
  getUserProfile: `get:api/user`,
  updateUserProfile: `post:api/user`,
  updateProfileImg: `post:api/user/image`,

  // Wish List
  addUserWishList: `post:api/wishlist/add`,
  removeProductFromWishList: `delete:api/wishlist/remove`,
  getAllWishList: `get:api/wishlist`,
  existsWishListOrNot: `get:api/exist/wishlist`,

  // Cart
  saveCart: `post:api/cart/add`,
  updateQtyInCart: `post:api/cart/quantity/update`,
  updateCustomer: `post:api/cart/login_user`,
  getCart: `get:api/cart/{order_id}`,
  deleteCart: `delete:api/cart/remove`,
  clearShoppingCart: `delete:api/clear/cart`,

  // Checkout
  getPaymentList: `get:api/payments`,
  payNow: `post:api/paynow`,
  getShippingAddress: `get:api/user/shippingaddress/{ID}`, //* if ID is given, respose only one  address
  createShippingAddress: `post:api/user/shippingaddress/add`,
  updateShippingAddress: `put:api/user/shippingaddress/{shipping_add_id}`,
  getDeliveryFee: `get:api/delivery/charge`,
  getShippingLocation: `get:api/delivery/locations/{country_code}`, //* if country code is omitted, response all country
  saveShippingAddressInfo: `post:api/order/shippingaddress`,
  saveInvoiceAddressInfo: `post:api/order/invoiceaddress`,


  // Other
  saveContactUsForm: `post:api/save/contactinformation`,
  getPage: `get:api/page/{code}`,
  getMetaDatas: `get:api/application/meta`,
  getWebsiteSlider: `get:api/image/slider/{code}`,
  getGoogleAnalyticTrackingCode: `get:api/google/analytic/trackingcode`,
  getLanguageDictionary: `get:api/dictionary`,
}