const initialState = {
  filterData: null
}

const emit = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'FILTERDATA':
      return {
        ...state,
        filterData: payload
      }
    default:
      return state
  }
}

export default emit