import styled from 'styled-components'

export const StyledPrimaryHeader = styled.div`
  background-color: ${props => props.theme.color.bgColor};
  border-bottom: 1px solid ${props => props.theme.color.grey};
  margin-bottom: 5px;
  padding: 20px 0;
  display: flex;
  align-items: center;
`

export const StyledSecondaryHeader = styled.div`
  background-color: ${props => props.theme.color.bgColor};
  border-bottom: 1px solid ${props => props.theme.color.grey};
  display: flex;
  align-items: center;

  &.sticky{
    position: fixed;
    top:0;
    width: 100%;
    z-index: 1;
  }

  nav{
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul{
      display: flex;
      padding: 0;
      margin: 0;
      li{
        list-style: none;
        cursor: pointer;
        margin-right: 20px;

        a,p{
          text-decoration: none;
          padding-top:  10px;
          padding-bottom: 10px;
          margin: 0;

          &.active{
            p{
              color: ${props => props.theme.color.primary};
              border-bottom: 2px solid ${props => props.theme.color.primary};
            }
          }

          &:hover{
            p{
              color: ${props => props.theme.color.primary};
              transition: color .3s ease-in-out;
            }
          }
        }
      }
    }
  }
`