import { rgba } from 'polished'
import styled from 'styled-components'

export const StyledLanguageSwitch = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;

  a,
  span {
    display: block;
    text-decoration: none;
    transition: border .2s ease;
    border-bottom: 2px solid transparent;
    position: relative;
    
    &.active {
      border-bottom: 2px solid ${({ theme }) => theme.color.primary};
      @media scree and (min-width: ${({ theme }) => theme.media.lg}px) {
        border-color: ${({ theme }) => theme.color.primary};
      }
      &:before {
        content: '';
        @media screen and (min-width: ${({ theme }) => theme.media.lg}px) {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 7px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid ${({ theme }) => theme.color.primary};
        }
      }
    }
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      position: absolute;
      top: 5px;
      right: -17px;
      transition: transform .3s ease;
    }
  }

  .drp-wrap {
    width: 150px;
    position: absolute;
    top: 100%;
    right: 0;
    left: initial;
    @media screen and (min-width: ${({ theme }) => theme.media.lg}px) {
      left: 0;
      right: initial;
    }
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    z-index: 1;
    border: ${({ theme }) => theme.inputGeneral.border} ${({ theme }) => theme.color.light};
    box-shadow: 0px 0px 10px ${({ theme }) => rgba(theme.color.secondary, .2)};
    border-radius: .1rem;
    background-color: ${props => props.bgColor || props.theme.color.card};
    transform: translateY(10px);

    .drp-ul {
      display: block;
      color: ${({ theme }) => theme.color.text};
      padding: 0;
      margin: 0;

      li {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: background .3s ease;
        padding: 7px 8px;
        border-bottom: 1px solid ${({ theme }) => theme.color.grey};

        &.active{
          border-color: red; 
        }

        a {
          display: block;
          width: 100%;
          color: ${({ theme }) => theme.color.text};
          font-size: ${({ theme }) => theme.fontSize.sm}px;
          border-bottom: 0;
          padding-bottom: 0;
        }

        span{
          margin-left: 5px;
        }

        &:hover {
          color: ${({ theme }) => theme.color.secondary};
          background-color: ${({ theme }) => theme.color.grey};
        }
      }
    }
  }

  &:hover {
    .drp-wrap {
      opacity: 1;
      visibility: visible;
      z-index: 9;
      transform: translateY(0);
    }
    span {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`