import styled from 'styled-components'
import { rgba } from 'polished'

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 130px;
  border-radius: 5px;
  margin: 2px;
  background-color: ${props => props.theme.color.primary};
  border-color: ${props => rgba(props.theme.color.primary, .9)};
  color: ${props => props.theme.color.light};

  &:focus{
    border-color: ${props => rgba(props.theme.color.primary, .9)};
    box-shadow: 0 0 0 0.25rem ${props => rgba(props.theme.color.primary, .2)};
  }

  &:hover{
    opacity: .9;
    color: ${props => rgba(props.theme.color.light, .9)};
  }

  &.btn{
  }

  p{
    margin-bottom:0
  }
`

export const StyledQtyBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .btn{
    height: 100%;
    min-width: 35px;
    padding: 0;
    margin: 0;
    border-radius: 0;

    &.add{
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.sub{
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    svg{
      color: ${({ theme }) => theme.color.light}
    }
  }

  input{
    max-width: 60px;
    padding: 5px;
  }
`

export const StyledBtnImage = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`