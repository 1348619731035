import React, { useEffect, useState } from 'react'
import { StyledSideBarFilter } from '@core'
import { useDispatch, useSelector } from 'react-redux'
import { AiTwotoneFilter, AiOutlineRight } from 'react-icons/ai'

import { emit } from '@actions'
import { CardCom } from '../card'
import { TextCom } from '../typo'
import { ButtonCom } from '../button'
import { CheckBoxCom, InputCom } from '../form'
import { addChecked, checkChange, getCheckId } from '@utils'

const queryString = require('query-string')
export const SideBarFilterCom = props => {
  const { history, category_data, brand_data, country_data } = props
  const { filterData } = useSelector(state => state.emit)
  const dispatch = useDispatch()

  useEffect(() => {
    let obj = queryString.parse(history.location.search, { arrayFormat: 'comma' })
    dispatch(emit.setEmit('FILTERDATA', { ...obj }))
    obj?.brand_ids && addChecked('id', brand_data, [...obj.brand_ids])
    obj?.country_ids && addChecked('id', country_data, [...obj.country_ids])
  }, [])

  useEffect(() => {
    handleUrlFilter()
  }, [filterData?.brand_ids, filterData?.country_ids, filterData?.category_id])

  const handleChange = async (key, obj, arr) => {
    let result = await checkChange('id', arr, obj?.id)
    let filter_res = await getCheckId('id', result)
    dispatch(emit.setEmit('FILTERDATA', {
      ...filterData,
      [key]: filter_res || obj
    }))
  }

  const onHandlePrice = () => {
    handleUrlFilter()
  }

  const handleUrlFilter = () => {
    filterData && history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(filterData, { skipNull: true, arrayFormat: 'comma' })
    })
  }

  const handleCategory = (key, obj) => {
    obj !== +filterData.category_id ? handleChange(key, obj) : handleChange(key, null)
  }

  const handleActive = (x) => {
    if (x.sub_category?.length > 0) {
      if (x.sub_category.some(y => +filterData?.category_id === y.id)) {
        return 'active'
      } else {
        return undefined
      }
    } else {
      if (+filterData?.category_id === x.id) {
        return 'active'
      } else {
        return undefined
      }
    }
  }

  return (
    <StyledSideBarFilter>
      {category_data.length > 0 && <CardCom className='cont'>
        <TextCom className='filter-title' size='lg' weight='lg'>Category Filter</TextCom>
        <ul>
          {
            category_data.map((x, i) => (
              <li
                key={i}
                className={handleActive(x)}
                onClick={() => !(x.sub_category?.length > 0) && handleCategory('category_id', x.id)}
              >
                <span>{x.name}</span>
                {x.sub_category?.length > 0 && <AiOutlineRight />}
                {
                  x.sub_category?.length > 0 &&
                  <ul className='sub-drp'>
                    {x.sub_category.map((sub_cat, index) => (
                      <li
                        key={index}
                        onClick={() => handleCategory('category_id', sub_cat.id)}
                        className={+filterData?.category_id === sub_cat.id ? 'active' : undefined}
                      >
                        <span>{sub_cat.name}</span>
                      </li>
                    ))}
                  </ul>
                }
              </li>
            ))
          }
        </ul>
      </CardCom>}
      {
        brand_data.length > 0 &&
        <CardCom className='cont'>
          <TextCom className='filter-title' size='lg' weight='lg'>Brand Filter</TextCom>
          {
            brand_data.map((x, i) => (
              <div className='item-wrap' key={i}>
                <CheckBoxCom
                  id={`brand_check_${i}`}
                  type='checkbox'
                  label={x.name}
                  checked={x.checked}
                  onChange={() => handleChange('brand_ids', x, brand_data)}
                />
              </div>
            ))
          }
        </CardCom>
      }
      <CardCom className='cont'>
        <TextCom className='filter-title' size='lg' weight='lg'>Filter by price</TextCom>
        <div className='price-filter-wrap'>
          <InputCom
            placeholder='Min'
            value={filterData?.min}
            onChange={e => handleChange('min', e.target.value)}
            type='number'
          />
          <InputCom
            placeholder='Max'
            value={filterData?.max}
            onChange={e => handleChange('max', e.target.value)}
            type='number'
          />
          <ButtonCom
            SVGL={<AiTwotoneFilter size={20} />}
            onClick={onHandlePrice}
            className='btn'
          />
        </div>
      </CardCom>
      {
        country_data.length > 0 &&
        <CardCom className='cont'>
          <TextCom className='filter-title' size='lg' weight='lg'>Country of origin</TextCom>
          {
            country_data.map((x, i) => (
              <div className='item-wrap' key={i}>
                <CheckBoxCom
                  id={`country_check_${i}`}
                  type='checkbox'
                  checked={x.checked}
                  label={x.name}
                  onChange={() => handleChange('country_ids', x, country_data)}
                />
              </div>
            ))
          }
        </CardCom>
      }
    </StyledSideBarFilter>
  )
}

SideBarFilterCom.defaultProps = {
  brand_data: [],
  country_data: [],
  category_data: []
}