import React, { useState, useContext, useEffect } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark, atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { BsCodeSlash, BsCode } from 'react-icons/bs'
import { ThemeContext } from 'styled-components'
import Markdown from 'markdown-to-jsx'

import { StyledMarkDown } from '@core'
import { ButtonCom } from '../button'
import { CardCom } from '../card'

export const MarkDownCom = ({ lang, filename, type, ...props }) => {
  const [collapse, setCollapse] = useState(false)
  const themeContext = useContext(ThemeContext)
  const [post, setPost] = useState('')

  useEffect(() => {
    import(`./file/${filename}.md`)
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setPost(res))
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))

    // document.querySelectorAll(`pre code`).forEach(function (codeBlock) {
    //   var button = document.createElement('button')
    //   button.className = 'copy-code-button'
    //   button.type = 'button'
    //   button.innerText = 'Copy'

    //   var pre = codeBlock.parentNode
    //   if (pre.parentNode.classList.contains('highlight')) {
    //     var highlight = pre.parentNode
    //     highlight.parentNode.insertBefore(button, highlight)
    //   } else {
    //     pre.parentNode.insertBefore(button, pre)
    //   }

    //   const code = codeBlock.innerText

    //   button.addEventListener('click', () => {
    //     window.navigator.clipboard.writeText(code)
    //     button.classList.add('copied')

    //     setTimeout(() => {
    //       button.classList.remove('copied')
    //     }, 2000)
    //   })
    // })
  })

  const CodeBlock = ({ className, children }) => {
    if (className && className.startsWith('lang-')) {
      lang = className.replace('lang-', '')
    }
    return (
      <SyntaxHighlighter
        language={lang}
        wrapLongLines={true}
        style={themeContext.color.mode === 'dark' ? atomOneDark : atomOneLight}
      >
        {children}
      </SyntaxHighlighter>
    )
  }

  const PreBlock = ({ children, ...rest }) => {
    if ('type' in children && children['type'] === 'code') {
      return CodeBlock(children['props'])
    }
    return <pre {...rest}>{children}</pre>
  }

  return (
    <>
      {
        type === 'collapse'
        &&
        <>
          <ButtonCom
            SVGL={!collapse ? <BsCodeSlash size={20} /> : <BsCode size={20} />}
            className='btn'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target={`#${filename}`}
            aria-expanded='false'
            aria-controls={`${filename}`}
            onClick={() => setCollapse(!collapse)}
          />
          <div className='collapse' id={`${filename}`}>
            <CardCom style={{ padding: 20 }}>
              <StyledMarkDown className='highlight'>
                <Markdown
                  options={{
                    overrides: {
                      pre: PreBlock,
                    },
                  }}
                >
                  {post}
                </Markdown>
              </StyledMarkDown>
            </CardCom>
          </div>
        </>
      }
      {
        type === 'section' &&
        <StyledMarkDown className='highlight'>
          <Markdown
            options={{
              overrides: {
                pre: PreBlock,
              },
            }}
          >
            {post}
          </Markdown>
        </StyledMarkDown>
      }
    </>
  )
}

MarkDownCom.defaultProps = {
  lang: 'github',
  filename: 'button',
  type: 'collapse'
}