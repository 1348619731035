import { actionTypes } from '../actions/checkout.action'

const initialState = {
  error: null,
  isLoading: false,
  payemntData: null,
  payNowData: null,
  shippingAddressData: null,
  createShippingAddressData: null,
  updateShippingAddressData: null,
  deliveryFeeData: null,
  shippingLocationData: null,
  saveShippingAddressInfo: null,
  saveInvoiceAddressInfo: null
}

const checkOut = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETPAYMENTLIST_REQUEST: // getPaymentList
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPAYMENTLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payemntData: action.data
      }
    case actionTypes.GETPAYMENTLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.PAYNOW_REQUEST: // payNow
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.PAYNOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payNowData: action.data
      }
    case actionTypes.PAYNOW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETSHIPPINGADDRESS_REQUEST: // getShippingAddress
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETSHIPPINGADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shippingAddressData: action.data
      }
    case actionTypes.GETSHIPPINGADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.CREATESHIPPINGADDRESS_REQUEST: // createShippingAddress
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.CREATESHIPPINGADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createShippingAddressData: action.data
      }
    case actionTypes.CREATESHIPPINGADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.UPDATESHIPPINGADDRESS_REQUEST: // updateShippingAddress
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.UPDATESHIPPINGADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateShippingAddressData: action.data
      }
    case actionTypes.UPDATESHIPPINGADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETDELIVERYFEE_REQUEST: // getDeliveryFee
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETDELIVERYFEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deliveryFeeData: null
      }
    case actionTypes.GETDELIVERYFEE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETSHIPPINGLOCATION_REQUEST: // getShippingLocation
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETSHIPPINGLOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shippingLocationData: null
      }
    case actionTypes.GETSHIPPINGLOCATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.SAVESHIPPINGADDRESSINFO_REQUEST: // saveShippingAddressInfo
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.SAVESHIPPINGADDRESSINFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveShippingAddressInfo: null
      }
    case actionTypes.SAVESHIPPINGADDRESSINFO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.SAVEINVOICEADDRESSINFO_REQUEST: // getDeliveryFee
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.SAVEINVOICEADDRESSINFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveInvoiceAddressInfo: null
      }
    case actionTypes.SAVEINVOICEADDRESSINFO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default checkOut