import React from 'react'
import { useField } from 'formik'
import { MdKeyboardArrowDown } from 'react-icons/md'

import { FieldWrapper } from './fieldwrapper'
import { InputCom } from '../form'

export const VSelectInput = props => {
  const [field, meta] = useField(props)
  return (
    <FieldWrapper meta={meta} {...props}>
      <InputCom
        as='select'
        className={`${meta.touched && meta.error ? 'error' : ''}`}
        select
        {...field}
        {...props}
      />
      <MdKeyboardArrowDown className='select-icon' />
    </FieldWrapper>
  )
}