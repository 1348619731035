import React from 'react'

import { StyledPrimaryHeader } from '@core'

export const PrimaryHeaderCom = props => {

  return (
    <StyledPrimaryHeader {...props} >
      {props.children}
    </StyledPrimaryHeader>
  )
}