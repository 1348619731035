import styled from 'styled-components'

export const StyledRating = styled.span`
 &.star{
  color: gray;
  cursor: pointer;
  font-size: 21px;
  margin-right: 25px;
  user-select: none;

  &:focus {
    outline: none;
  }
 }
`

export const StyledRatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  img{
    margin-right: 5px;
    width: 15px;
    height: 100%;
  }
`