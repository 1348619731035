import React from 'react'
import { Formik } from 'formik'

import { StyledInfoContainer } from '@core'
import { CardCom } from '../card'
import { TextCom } from '../typo'
import { RowCom } from '../grid'
import { ColCom } from '../grid'

export const ContactUsCom = ({ handleSubmit, initialValues, validationSchema, children, data, props }) => {

  const onDataSubmit = (values, actions) => {
    setTimeout(async () => {
      await handleSubmit(values)
      actions.resetForm(initialValues)
      actions.setSubmitting(false)
    }, 1000)
  }

  return (
    <CardCom>
      <StyledInfoContainer {...props} style={{ marginBottom: 20 }}>
        <RowCom>
          <ColCom lg='6' md='6' className='first-col'>
            <TextCom size='xxxl' weight='xxxl'>Contact Information</TextCom>
            <TextCom>{data?.address}</TextCom>
            <TextCom>{data?.phone}</TextCom>
            <TextCom>{data?.email}</TextCom>
          </ColCom>
          <ColCom lg='6' md='6' className='sec-col'>
            <TextCom size='xxxl' weight='xxxl'>Inqueries</TextCom>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => onDataSubmit(values, actions)}
            >
              {formikProps => children(formikProps)}
            </Formik>
          </ColCom>
        </RowCom>
      </StyledInfoContainer>
    </CardCom>
  )
}