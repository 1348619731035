import styled from 'styled-components'

export const StyledAuthContainer = styled.div`
  padding: 30px 50px;
  display: flex;
  flex-direction: column;

  .resend-btn{
    color: ${props => props.theme.color.primary};
    text-align: center;
    text-decoration: underline
  }
`