import styled from 'styled-components'

export const StyledBreadCrumb = styled.div`
ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      position: relative;

      padding-right: 16px;
      text-transform: capitalize;
      display: flex;
      align-items: center;

      svg{
        margin-right: 3px;
      }

      a{
        text-decoration: none;
        color: ${props => props.theme.color.primary}
      }

      &:after {
        content: '/';
        position: absolute;
        top: 3px;
        right: 5px;
      }
      &:first-child {
       
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  `
  /* font-size: ${fontSize.md}px; */