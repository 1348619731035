export const inputGeneral = {
  border: '1px solid',
  borderRadius: '1px',
  padding: '7px 15px',
  transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
  checkLabel: {
    'position': 'relative',
    'padding-left': '20px',
    'margin-bottom': 0,
    'width': 'auto',
    'text-align': 'left',
    'cursor': 'pointer',
    'text-transform': 'lowercase',
    'user-select': 'none'
  }
}

// border 

// borderradius

// width

// height