import { controller, routes, routeFilter } from '@controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  // userSignUp
  USERSIGNUP_REQUEST: 'USERSIGNUP_REQUEST',
  USERSIGNUP_ERROR: 'USERSIGNUP_ERROR',
  USERSIGNUP_SUCCESS: 'USERSIGNUP_SUCCESS',

  // otpVerify
  OTPVERIFY_REQUEST: 'OTPVERIFY_REQUEST',
  OTPVERIFY_ERROR: 'OTPVERIFY_ERROR',
  OTPVERIFY_SUCCESS: 'OTPVERIFY_SUCCESS',

  // checkUserByPhone
  CHECKUSERBYPHONE_REQUEST: 'CHECKUSERBYPHONE_REQUEST',
  CHECKUSERBYPHONE_ERROR: 'CHECKUSERBYPHONE_ERROR',
  CHECKUSERBYPHONE_SUCCESS: 'CHECKUSERBYPHONE_SUCCESS',

  // resetPassword
  RESETPASSWORD_REQUEST: 'RESETPASSWORD_REQUEST',
  RESETPASSWORD_ERROR: 'RESETPASSWORD_ERROR',
  RESETPASSWORD_SUCCESS: 'RESETPASSWORD_SUCCESS',

  // getSaleOrderByUserId
  GETSALEORDERBYUSERID_REQUEST: 'GETSALEORDERBYUSERID_REQUEST',
  GETSALEORDERBYUSERID_ERROR: 'GETSALEORDERBYUSERID_ERROR',
  GETSALEORDERBYUSERID_SUCCESS: 'GETSALEORDERBYUSERID_SUCCESS',

  // getSaleOrderById
  GETSALEORDERBYID_REQUEST: 'GETSALEORDERBYID_REQUEST',
  GETSALEORDERBYID_ERROR: 'GETSALEORDERBYID_ERROR',
  GETSALEORDERBYID_SUCCESS: 'GETSALEORDERBYID_SUCCESS',

  // getUserProfile
  GETUSERPROFILE_REQUEST: 'GETUSERPROFILE_REQUEST',
  GETUSERPROFILE_ERROR: 'GETUSERPROFILE_ERROR',
  GETUSERPROFILE_SUCCESS: 'GETUSERPROFILE_SUCCESS',

  // updateUserProfile
  UPDATEUSERPROFILE_REQUEST: 'UPDATEUSERPROFILE_REQUEST',
  UPDATEUSERPROFILE_ERROR: 'UPDATEUSERPROFILE_ERROR',
  UPDATEUSERPROFILE_SUCCESS: 'UPDATEUSERPROFILE_SUCCESS',

  // updateProfileImg
  UPDATEPROFILEIMG_REQUEST: 'UPDATEPROFILEIMG_REQUEST',
  UPDATEPROFILEIMG_ERROR: 'UPDATEPROFILEIMG_ERROR',
  UPDATEPROFILEIMG_SUCCESS: 'UPDATEPROFILEIMG_SUCCESS',
}

const userSignUp = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.USERSIGNUP_REQUEST))
  return await controller(routes.userSignUp, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.USERSIGNUP_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.USERSIGNUP_ERROR, error.message)))
}

const otpVerify = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.OTPVERIFY_REQUEST))
  return await controller(routes.otpVerify, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.OTPVERIFY_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.OTPVERIFY_ERROR, error.message)))
}

const checkUserByPhone = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.CHECKUSERBYPHONE_REQUEST))
  return await controller(routes.checkUserByPhone, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.CHECKUSERBYPHONE_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.CHECKUSERBYPHONE_ERROR, error.message)))
}

const resetPassword = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.RESETPASSWORD_REQUEST))
  return await controller(routes.resetPassword, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.RESETPASSWORD_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.RESETPASSWORD_ERROR, error.message)))
}

const getSaleOrderByUserId = (page_no, per_page) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETSALEORDERBYUSERID_REQUEST))
  return await controller(`${routes.getSaleOrderByUserId}/${page_no}/${per_page}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETSALEORDERBYUSERID_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETSALEORDERBYUSERID_ERROR, error.message)))
}

const getSaleOrderById = id => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETSALEORDERBYID_REQUEST))
  return await controller(`${routes.getSaleOrderById}/${id}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETSALEORDERBYID_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETSALEORDERBYID_ERROR, error.message)))
}

const getUserProfile = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETUSERPROFILE_REQUEST))
  return await controller(routes.getUserProfile)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETUSERPROFILE_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETUSERPROFILE_ERROR, error.message)))
}

const updateUserProfile = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.UPDATEUSERPROFILE_REQUEST))
  return await controller(routes.updateUserProfile, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.UPDATEUSERPROFILE_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.UPDATEUSERPROFILE_ERROR, error.message)))
}

const updateProfileImg = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.UPDATEPROFILEIMG_REQUEST))
  return await controller(routes.updateProfileImg, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.UPDATEPROFILEIMG_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.UPDATEPROFILEIMG_ERROR, error.message)))
}

export const userAction = {
  userSignUp,
  otpVerify,
  checkUserByPhone,
  resetPassword,
  getSaleOrderByUserId,
  getSaleOrderById,
  getUserProfile,
  updateUserProfile,
  updateProfileImg
}