import styled from 'styled-components'

export const StyledSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button{
    height: 100%;
    margin: 0;
    padding: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: 35px;
  }
`