import React from 'react'
import { useField, useFormikContext } from 'formik'

import { FieldWrapper } from './fieldwrapper'
import { SelectDateCom } from '../form'

export const VSelectDate = props => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props)

  return (
    <FieldWrapper meta={meta} {...props}>
      <SelectDateCom
        {...field}
        {...props}
        className={`select-date-field ${meta.touched && meta.error ? 'error' : ''}`}
        onDateChange={val => {
          setFieldValue(field.name, val);
        }}
      />
    </FieldWrapper>
  )
}