import { actionTypes } from '../actions/cart.action'

const initialState = {
  error: null,
  isLoading: false,
  saveCartData: null,
  updateQtyData: null,
  updateCustomerData: null,
  cartData: null,
  deleteCartData: null,
  clearShoppingCartData: null
}

const cart = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVECART_REQUEST: // saveCart
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.SAVECART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveCartData: action.data
      }
    case actionTypes.SAVECART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.UPDATEQTYINCART_REQUEST: // updateQtyInCart
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.UPDATEQTYINCART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateQtyData: action.data
      }
    case actionTypes.UPDATEQTYINCART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.UPDATECUSTOMER_REQUEST: // updateCustomer
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.UPDATECUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateCustomerData: action.data
      }
    case actionTypes.UPDATECUSTOMER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETCART_REQUEST: // getCart
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cartData: action.data
      }
    case actionTypes.GETCART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.DELETECART_REQUEST: // deleteCart
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.DELETECART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteCartData: action.data
      }
    case actionTypes.DELETECART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.CLEARSHOPPINGCART_REQUEST: // clearShoppingCart
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.CLEARSHOPPINGCART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clearShoppingCartData: null
      }
    case actionTypes.CLEARSHOPPINGCART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default cart