import httpClient from './constant/httpclient'
const queryString = require('query-string')

export const routeFilter = params => {
  let str = queryString.stringify(params)
  return str !== '' ? `?${str}` : str
}

export const controller = async (endpoint, ...data) => {
  let tmp = endpoint.split(':')
  return await httpClient[tmp[0]](tmp[1], ...data)
    .then(res => res && res)
    .catch(error => error)
}

export * from './constant/routes'