import styled, { css } from 'styled-components'
import { media } from '../../attribute'

const percent = (val) => Number(val / 12) * 100

export const StyledMain = styled.main`
  min-height: calc(100vh - 250px);
  background-color: ${({ theme }) => theme.color.bgColor}
`

export const StyledSection = styled.section`
  padding-top: 30px;
  padding-bottom: 30px;
  background: ${props => props.theme.color.bgColor};
  @media screen and (min-width: ${media.md}px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  ${({ fluid }) => fluid && css`
    max-width: unset;
  `}

  .auth-section{
    display: flex;
    align-items: center;
    flex-direction: column;
    
    .auth-card{
      width: 90%;
      margin-bottom: 20px;
      
      @media screen and (min-width: ${media.md}px) {
        width: 50%;
      }
    }
  }
`

export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
`

export const StyledCol = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 15px;
  box-sizing: border-box;
  ${({ space }) => space && css`
    width: ${percent(space)}%;
  `}
  ${({ sm }) => sm && css`
    @media screen and (min-width: ${media.sm}px) {
      width: ${percent(sm)}%;
    }
  `}
  ${({ md }) => md && css`
    @media screen and (min-width: ${media.md}px) {
      width: ${percent(md)}%;
    }
  `}
  ${({ lg }) => lg && css`
    @media screen and (min-width: ${media.lg}px) {
      width: ${percent(lg)}%;
    }
  `}
  ${({ xl }) => xl && css`
    @media screen and (min-width: ${media.xl}px) {
      width: ${percent(xl)}%;
    }
  `}
  ${({ offset }) => offset && css`
    margin-left: ${percent(offset)}%;
  `}
  ${({ offset_sm }) => offset_sm && css`
    @media screen and (min-width: ${media.sm}px) {
      margin-left: ${percent(offset_sm)}%;
    }
  `}
  ${({ offset_md }) => offset_md && css`
    @media screen and (min-width: ${media.md}px) {
      margin-left: ${percent(offset_md)}%;
    }
  `}
  ${({ offset_lg }) => offset_lg && css`
    @media screen and (min-width: ${media.lg}px) {
      margin-left: ${percent(offset_lg)}%;
    }
  `}
`