export const lightcolor = {
  mode: 'light',
  text: '#000',
  primary: '#1E91D6',
  secondary: '#2E3192',
  success: '#198754',
  info: '#0dcaf0',
  warning: '#ffc107',
  danger: '#dc3545',
  light: '#fff',
  dark: '#000',
  grey: '#EEEEEE',

  bgColor: '#fff',
  card: '#ffff',
  hoverColor: '#3A3B3C',
}

export const darkcolor = {
  mode: 'dark',
  text: '#E4E6EB',
  primary: '#1E91D6',
  secondary: '#2E3192',
  success: '#198754',
  info: '#0dcaf0',
  warning: '#ffc107',
  danger: '#dc3545',
  light: '#fff',
  dark: '#000',
  grey: '#EEEEEE',

  bgColor: '#000',
  card: '#242526',
  hoverColor: '#3A3B3C',
}

export const overseacolor = {
  mode: 'oversea',
  text: '#000',
  primary: '#1E91D6',
  secondary: '#2E3192',
  success: '#198754',
  info: '#0dcaf0',
  warning: '#ffc107',
  danger: '#dc3545',
  light: '#fff',
  dark: '#000',
  grey: '#EEEEEE',

  bgColor: '#fff',
  card: '#242526',
  hoverColor: '#3A3B3C',
}