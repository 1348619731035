import styled from 'styled-components'
import ReactSlick from 'react-slick'

export const StyledSlider = styled(ReactSlick)`

  .slick-arrow {
    &.slick-prev, &.slick-next{
      background-color: rgb(187, 184, 184);
      border-radius: 10px;
  
      &:hover, &:focus {
        background-color: rgb(187, 184, 184);
        border-radius: 10px;
      }
    }
  }
`