import React from 'react'

import { StyledMain } from '@core'

export const MainCom = props => {
  const { children } = props
  return (
    <StyledMain {...props}>
      {children}
    </StyledMain>
  )
}