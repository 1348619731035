import React, { useContext } from 'react'
import ReactImageMagnify from 'react-image-magnify'
import { StyledSlider } from '@core'
import { ThemeContext } from 'styled-components'

import { ImageCom } from '../image'
import { CardCom } from '../card'
import { TextCom } from '../typo'

export const SliderCom = props => {
  const { rimProps, rsProps, type, data } = props
  const themeContext = useContext(ThemeContext)

  let img_media = ['500w', '779w', '1020w', '1200w', '1426w']

  let dataSource = data.map(x => (
    {
      ...x,
      srcSet: img_media.map(y => `${x.image || require('@asset/image/home_cart_1.png').default} ${y}`).join(', '),
      small: x.image || require('@asset/image/home_cart_1.png').default,
      large: x.image || require('@asset/image/home_cart_1.png').default
    }
  ))

  let RSProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: themeContext.media.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true
        }
      },
      {
        breakpoint: themeContext.media.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ],
    ...rsProps
  }

  let RIMProps = src => ({
    smallImage: {
      alt: 'Wristwatch by Versace',
      isFluidWidth: true,
      src: src.small,
      srcSet: src.srcSet,
      sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
    },
    largeImage: {
      src: src.large,
      width: 1426,
      height: 2000
    },
    lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' },
    ...rimProps
  })

  return (
    <StyledSlider {...RSProps} {...props}>
      {dataSource?.length > 0 && dataSource.map((src, index) => (
        <div key={index}>
          {type === 'zoom' && <ReactImageMagnify {...RIMProps(src)} />}
          {type === 'simple' && <ImageCom width={255} height={400} img={src.large} />}
          {type === 'hero' &&
            <ImageCom width={255} height={400} img={src.large} >
              <div className='inner-cont'>
                <TextCom color='light' size='xl' weight='xl'>{src.name}</TextCom>
                <TextCom color='light'>ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac.</TextCom>
              </div>
            </ImageCom>
          }
          {type === 'product' && <CardCom data={src} type='product' />}
        </div>
      ))}
    </StyledSlider>
  )
}

SliderCom.defaultProps = {
  data: [],
  type: 'simple'
}