import { langStore } from '@service'
import { actionTypes } from '../actions/other.action'

const initialState = {
  error: null,
  isLoading: false,
  contactUsData: null,
  pageData: null,
  metaData: null,
  websiteSliderData: null,
  googleAnalyticTrackingCodeData: null,
  dictionaryData: null,
  langStore: langStore.getLanguage() ?
    langStore.getLanguage() :
    {
      lang: 'English',
      code: 'en',
      lan_icon: require('@asset/icon/en-flag.svg').default
    }
}

const other = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVECONTACTUSFORM_REQUEST: // saveContactUsForm
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.SAVECONTACTUSFORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contactUsData: action.data
      }
    case actionTypes.SAVECONTACTUSFORM_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPAGE_REQUEST: // getPage
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pageData: action.data
      }
    case actionTypes.GETPAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETMETADATAS_REQUEST: // getMetaDatas
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETMETADATAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        metaData: action.data
      }
    case actionTypes.GETMETADATAS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETWEBSITESLIDER_REQUEST: // getWebsiteSlider
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETWEBSITESLIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        websiteSliderData: action.data
      }
    case actionTypes.GETWEBSITESLIDER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETGOOGLEANALYTICTRACKINGCODE_REQUEST: // getGoogleAnalyticTrackingCode
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETGOOGLEANALYTICTRACKINGCODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        googleAnalyticTrackingCodeData: action.data
      }
    case actionTypes.GETGOOGLEANALYTICTRACKINGCODE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETLANGUAGEDICTIONARY_REQUEST: // getLanguageDictionary
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETLANGUAGEDICTIONARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dictionaryData: action.data
      }
    case actionTypes.GETLANGUAGEDICTIONARY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'LANG_CODE_OBJ':
      langStore.setLanguage(action.data)
      return {
        ...state,
        langStore: action.data
      }
    default:
      return state
  }
}

export default other