import * as React from 'react'
import url from 'url'
import { removeUndefined } from './utils'


const sanitizeHex = hex => {
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
    throw new Error(`Expected valid hex color, got "${hex}"`)
  }

  return hex.substring(1) // remove the hash
}

export const generateCustomPlaceholderURL = (width, height, options) => {
  const { backgroundColor, textColor, text } = options

  return url.format({
    protocol: 'https',
    host: 'via.placeholder.com',
    pathname: [
      `${width}x${height}`,
      backgroundColor ? sanitizeHex(backgroundColor) : '',
      textColor ? sanitizeHex(textColor) : undefined,
    ]
      .filter(part => part !== undefined)
      .join('/'),
    query: removeUndefined({
      text,
    }),
  })
}

export const CustomPlaceholder = (props) => {
  const {
    width,
    height,
    backgroundColor,
    textColor,
    text,
    src,
    ...htmlProps
  } = props

  if (src !== undefined) {
    console.warn(
      'Do not define the `src` prop for <CustomPlaceholder /> the src will be generated'
    )
  }

  return React.createElement('img', {
    src: generateCustomPlaceholderURL(width, height, {
      backgroundColor,
      textColor,
      text,
    }),
    width,
    height,
    alt: 'placeholder',
    ...htmlProps,
  })
}