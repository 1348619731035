import React from 'react'
import { Link } from 'react-router-dom'

import { StyledCard, StyledCardTitle, StyledCardContent, StyledCardFooter } from '@core'
import { moneyFormat } from '@utils'
import { ImageCom } from '../image'
import { TextCom } from '../typo'
import { ButtonCom } from '../button'
import { RatingCom } from '../rating'
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai"

export const CardCom = ({ title, count, img, link, data, ...props }) => {

  return (
    <StyledCard {...props}>
      {props.type === 'simple' &&
        <>
          {
            props.children ?
              props.children
              :
              <TextCom>
                Required Your Children Component
              </TextCom>
          }
        </>
      }
      {props.type === 'product' &&
        <Link to={link || ''}>
          {title && <StyledCardTitle>
            <TextCom>Card Title</TextCom>
          </StyledCardTitle>}
          <ImageCom
            width={255}
            height={250}
            img={data?.image}
          />
          <StyledCardContent {...props}>
            {
              props.children ?
                props.children
                :
                <>
                  <div className='card-content-wrap'>
                    <TextCom>{data?.name}</TextCom>
                    <TextCom className='desc-wrap'>{data?.des}</TextCom>
                    <div className='rating-wrap'>
                      {count >= 0 && <RatingCom count={count} />}
                      {data.checked ? <AiFillHeart size={20} /> : <AiOutlineHeart size={20} />}
                    </div>
                    <div className='price-wrap'>
                      <TextCom className='del'>{moneyFormat(1000)} Ks</TextCom>
                      <TextCom color='primary' weight='xl'>{moneyFormat(1000)} Ks</TextCom>
                    </div>
                  </div>
                  <StyledCardFooter>
                    <ButtonCom
                      type='button'
                      className='btn'
                    >
                      Detail
                    </ButtonCom>
                  </StyledCardFooter>
                </>
            }
          </StyledCardContent>
        </Link>
      }
    </StyledCard>
  )
}

CardCom.defaultProps = {
  count: 0,
  type: 'simple'
}