import { combineReducers } from 'redux'

import theme from './theme.reducer'
import emit from './emit.reducer'
import auth from './auth.reducer'
import cart from './cart.reducer'
import checkOut from './checkout.reducer'
import other from './other.reducer'
import product from './product.reducer'
import user from './user.reducer'
import wishList from './wishlist.reducer'

export default combineReducers({
  auth,
  cart,
  checkOut,
  other,
  product,
  user,
  wishList,
  theme,
  emit
})