import { controller, routes, routeFilter } from '@controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  // getProductList
  GETPRODUCTLIST_REQUEST: 'GETPRODUCTLIST_REQUEST',
  GETPRODUCTLIST_ERROR: 'GETPRODUCTLIST_ERROR',
  GETPRODUCTLIST_SUCCESS: 'GETPRODUCTLIST_SUCCESS',

  // getProductRatingAndReview
  GETPRODUCTRATINGANDREVIEW_REQUEST: 'GETPRODUCTRATINGANDREVIEW_REQUEST',
  GETPRODUCTRATINGANDREVIEW_ERROR: 'GETPRODUCTRATINGANDREVIEW_ERROR',
  GETPRODUCTRATINGANDREVIEW_SUCCESS: 'GETPRODUCTRATINGANDREVIEW_SUCCESS',

  // addProductRatingAndReview
  ADDPRODUCTRATINGANDREVIEW_REQUEST: 'ADDPRODUCTRATINGANDREVIEW_REQUEST',
  ADDPRODUCTRATINGANDREVIEW_ERROR: 'ADDPRODUCTRATINGANDREVIEW_ERROR',
  ADDPRODUCTRATINGANDREVIEW_SUCCESS: 'ADDPRODUCTRATINGANDREVIEW_SUCCESS',

  // getRelatedProduct
  GETRELATEDPRODUCT_REQUEST: 'GETRELATEDPRODUCT_REQUEST',
  GETRELATEDPRODUCT_ERROR: 'GETRELATEDPRODUCT_ERROR',
  GETRELATEDPRODUCT_SUCCESS: 'GETRELATEDPRODUCT_SUCCESS',

  // getProductDetail
  GETPRODUCTDETAIL_REQUEST: 'GETPRODUCTDETAIL_REQUEST',
  GETPRODUCTDETAIL_ERROR: 'GETPRODUCTDETAIL_ERROR',
  GETPRODUCTDETAIL_SUCCESS: 'GETPRODUCTDETAIL_SUCCESS',

  // getProductCategory
  GETPRODUCTCATEGORY_REQUEST: 'GETPRODUCTCATEGORY_REQUEST',
  GETPRODUCTCATEGORY_ERROR: 'GETPRODUCTCATEGORY_ERROR',
  GETPRODUCTCATEGORY_SUCCESS: 'GETPRODUCTCATEGORY_SUCCESS',

  // getCountryOrigin
  GETCOUNTRYORIGIN_REQUEST: 'GETCOUNTRYORIGIN_REQUEST',
  GETCOUNTRYORIGIN_ERROR: 'GETCOUNTRYORIGIN_ERROR',
  GETCOUNTRYORIGIN_SUCCESS: 'GETCOUNTRYORIGIN_SUCCESS',

  // getBrandList
  GETBRANDLIST_REQUEST: 'GETBRANDLIST_REQUEST',
  GETBRANDLIST_ERROR: 'GETBRANDLIST_ERROR',
  GETBRANDLIST_SUCCESS: 'GETBRANDLIST_SUCCESS',

  // getProductsFromProductGroup
  GETPRODUCTSFROMPRODUCTGROUP_REQUEST: 'GETPRODUCTSFROMPRODUCTGROUP_REQUEST',
  GETPRODUCTSFROMPRODUCTGROUP_ERROR: 'GETPRODUCTSFROMPRODUCTGROUP_ERROR',
  GETPRODUCTSFROMPRODUCTGROUP_SUCCESS: 'GETPRODUCTSFROMPRODUCTGROUP_SUCCESS',

  // checkProductBalance
  CHECKPRODUCTBALANCE_REQUEST: 'CHECKPRODUCTBALANCE_REQUEST',
  CHECKPRODUCTBALANCE_ERROR: 'CHECKPRODUCTBALANCE_ERROR',
  CHECKPRODUCTBALANCE_SUCCESS: 'CHECKPRODUCTBALANCE_SUCCESS',

  // getOrderedProduct
  GETORDEREDPRODUCT_REQUEST: 'GETORDEREDPRODUCT_REQUEST',
  GETORDEREDPRODUCT_ERROR: 'GETORDEREDPRODUCT_ERROR',
  GETORDEREDPRODUCT_SUCCESS: 'GETORDEREDPRODUCT_SUCCESS',

  // updateProductViewCount
  UPDATEPRODUCTVIEWCOUNT_REQUEST: 'UPDATEPRODUCTVIEWCOUNT_REQUEST',
  UPDATEPRODUCTVIEWCOUNT_ERROR: 'UPDATEPRODUCTVIEWCOUNT_ERROR',
  UPDATEPRODUCTVIEWCOUNT_SUCCESS: 'UPDATEPRODUCTVIEWCOUNT_SUCCESS',

  // getProductListOfPromotion
  GETPRODUCTLISTOFPROMOTION_REQUEST: 'GETPRODUCTLISTOFPROMOTION_REQUEST',
  GETPRODUCTLISTOFPROMOTION_ERROR: 'GETPRODUCTLISTOFPROMOTION_ERROR',
  GETPRODUCTLISTOFPROMOTION_SUCCESS: 'GETPRODUCTLISTOFPROMOTION_SUCCESS',

  // getPromotionList
  GETPROMOTIONLIST_REQUEST: 'GETPROMOTIONLIST_REQUEST',
  GETPROMOTIONLIST_ERROR: 'GETPROMOTIONLIST_ERROR',
  GETPROMOTIONLIST_SUCCESS: 'GETPROMOTIONLIST_SUCCESS',
}

//? Params Data
// page_number, 
// per_page, 
// sort_by  
// (PRICEFROMHIGH, PRICEFROMLOW, NAMEASC, NAMEDESC), 
// category_id, 
// country_id,
// min_price,
// max_price,
// brand_id,
// lang,
// keywords
const getProductList = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRODUCTLIST_REQUEST))
  return await controller(`${routes.getProductList}${routeFilter(data)}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETPRODUCTLIST_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRODUCTLIST_ERROR, error.message)))
}

const getProductRatingAndReview = id => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRODUCTRATINGANDREVIEW_REQUEST))
  return await controller(`${routes.getProductRatingAndReview}/${id}/ratingreview`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETPRODUCTRATINGANDREVIEW_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRODUCTRATINGANDREVIEW_ERROR, error.message)))
}

const addProductRatingAndReview = (id, data) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.ADDPRODUCTRATINGANDREVIEW_REQUEST))
  return await controller(`${routes.addProductRatingAndReview}/${id}/ratingreview`, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.ADDPRODUCTRATINGANDREVIEW_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.ADDPRODUCTRATINGANDREVIEW_ERROR, error.message)))
}

const getRelatedProduct = (id, count) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETRELATEDPRODUCT_REQUEST))
  return await controller(`${routes.getRelatedProduct}/${id}/related/${count}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETRELATEDPRODUCT_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETRELATEDPRODUCT_ERROR, error.message)))
}

const getProductDetail = id => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRODUCTDETAIL_REQUEST))
  return await controller(`${routes.getProductDetail}/${id}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETPRODUCTDETAIL_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRODUCTDETAIL_ERROR, error.message)))
}

const getProductCategory = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRODUCTCATEGORY_REQUEST))
  return await controller(routes.getProductCategory)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETPRODUCTCATEGORY_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRODUCTCATEGORY_ERROR, error.message)))
}

const getCountryOrigin = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETCOUNTRYORIGIN_REQUEST))
  return await controller(routes.getCountryOrigin)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETCOUNTRYORIGIN_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETCOUNTRYORIGIN_ERROR, error.message)))
}

const getBrandList = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETBRANDLIST_REQUEST))
  return await controller(routes.getBrandList)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETBRANDLIST_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETBRANDLIST_ERROR, error.message)))
}

const getProductsFromProductGroup = group_code => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRODUCTSFROMPRODUCTGROUP_REQUEST))
  return await controller(`${routes.getProductsFromProductGroup}/${group_code}/products`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETPRODUCTSFROMPRODUCTGROUP_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRODUCTSFROMPRODUCTGROUP_ERROR, error.message)))
}

//? Params Data
// product_id, variant_id, quantity
const checkProductBalance = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.CHECKPRODUCTBALANCE_REQUEST))
  return await controller(`${routes.checkProductBalance}${routeFilter(data)}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.CHECKPRODUCTBALANCE_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.CHECKPRODUCTBALANCE_ERROR, error.message)))
}

const getOrderedProduct = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETORDEREDPRODUCT_REQUEST))
  return await controller(`${routes.getOrderedProduct}?lang=${lang}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETORDEREDPRODUCT_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETORDEREDPRODUCT_ERROR, error.message)))
}

const updateProductViewCount = id => async dispatch => {
  dispatch(ToDoRequest(actionTypes.UPDATEPRODUCTVIEWCOUNT_REQUEST))
  return await controller(`${routes.updateProductViewCount}/${id}/view`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.UPDATEPRODUCTVIEWCOUNT_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.UPDATEPRODUCTVIEWCOUNT_ERROR, error.message)))
}

const getProductListOfPromotion = id => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPRODUCTLISTOFPROMOTION_REQUEST))
  return await controller(`${routes.getProductListOfPromotion}/${id}/products`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETPRODUCTLISTOFPROMOTION_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPRODUCTLISTOFPROMOTION_ERROR, error.message)))
}

const getPromotionList = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPROMOTIONLIST_REQUEST))
  return await controller(routes.getPromotionList)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETPROMOTIONLIST_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPROMOTIONLIST_ERROR, error.message)))
}

export const productAction = {
  getProductList,
  getProductRatingAndReview,
  addProductRatingAndReview,
  getRelatedProduct,
  getProductDetail,
  getProductCategory,
  getCountryOrigin,
  getBrandList,
  getProductsFromProductGroup,
  checkProductBalance,
  getOrderedProduct,
  updateProductViewCount,
  getProductListOfPromotion,
  getPromotionList
}