import React from 'react'

import { StyledTableHeader, StyledTableHeaderCol } from '@core'

import { TrCom } from './body'

export const ThCom = ({ children, props }) => {
  return (
    <StyledTableHeaderCol {...props} >
      {children}
    </StyledTableHeaderCol>
  )
}

export const TableHeaderCom = ({ headerData, children, ...props }) => {

  return (
    <StyledTableHeader {...props}>
      <TrCom>
        {
          headerData?.length > 0 && headerData?.map((x, i) => (
            <ThCom key={i}>
              <span>{x.title}</span>
              {x?.sp_title && <span>{x.sp_title}</span>}
            </ThCom>
          ))
        }
      </TrCom>
    </StyledTableHeader>
  )
}

TableHeaderCom.defaultProps = {
  headerData: []
}