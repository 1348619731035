import { controller, routes, routeFilter } from '@controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  // saveContactUsForm
  SAVECONTACTUSFORM_REQUEST: 'SAVECONTACTUSFORM_REQUEST',
  SAVECONTACTUSFORM_ERROR: 'SAVECONTACTUSFORM_ERROR',
  SAVECONTACTUSFORM_SUCCESS: 'SAVECONTACTUSFORM_SUCCESS',

  // getPage
  GETPAGE_REQUEST: 'GETPAGE_REQUEST',
  GETPAGE_ERROR: 'GETPAGE_ERROR',
  GETPAGE_SUCCESS: 'GETPAGE_SUCCESS',

  // getMetaDatas
  GETMETADATAS_REQUEST: 'GETMETADATAS_REQUEST',
  GETMETADATAS_ERROR: 'GETMETADATAS_ERROR',
  GETMETADATAS_SUCCESS: 'GETMETADATAS_SUCCESS',

  // getWebsiteSlider
  GETWEBSITESLIDER_REQUEST: 'GETWEBSITESLIDER_REQUEST',
  GETWEBSITESLIDER_ERROR: 'GETWEBSITESLIDER_ERROR',
  GETWEBSITESLIDER_SUCCESS: 'GETWEBSITESLIDER_SUCCESS',

  // getGoogleAnalyticTrackingCode
  GETGOOGLEANALYTICTRACKINGCODE_REQUEST: 'GETGOOGLEANALYTICTRACKINGCODE_REQUEST',
  GETGOOGLEANALYTICTRACKINGCODE_ERROR: 'GETGOOGLEANALYTICTRACKINGCODE_ERROR',
  GETGOOGLEANALYTICTRACKINGCODE_SUCCESS: 'GETGOOGLEANALYTICTRACKINGCODE_SUCCESS',

  // getLanguageDictionary
  GETLANGUAGEDICTIONARY_REQUEST: 'GETLANGUAGEDICTIONARY_REQUEST',
  GETLANGUAGEDICTIONARY_ERROR: 'GETLANGUAGEDICTIONARY_ERROR',
  GETLANGUAGEDICTIONARY_SUCCESS: 'GETLANGUAGEDICTIONARY_SUCCESS',
}

const saveContactUsForm = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.SAVECONTACTUSFORM_REQUEST))
  return await controller(routes.saveContactUsForm, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.SAVECONTACTUSFORM_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.SAVECONTACTUSFORM_ERROR, error.message)))
}

const getPage = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPAGE_REQUEST))
  return await controller(`${routes.getPage}?lang=${lang}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETPAGE_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPAGE_ERROR, error.message)))
}

const getMetaDatas = lang => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETMETADATAS_REQUEST))
  return await controller(`${routes.getMetaDatas}?lang=${lang}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETMETADATAS_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETMETADATAS_ERROR, error.message)))
}

const getWebsiteSlider = code => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETWEBSITESLIDER_REQUEST))
  return await controller(`${routes.getWebsiteSlider}?code=${code}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETWEBSITESLIDER_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETWEBSITESLIDER_ERROR, error.message)))
}

const getGoogleAnalyticTrackingCode = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETGOOGLEANALYTICTRACKINGCODE_REQUEST))
  return await controller(routes.getGoogleAnalyticTrackingCode)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETGOOGLEANALYTICTRACKINGCODE_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETGOOGLEANALYTICTRACKINGCODE_ERROR, error.message)))
}

const getLanguageDictionary = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETLANGUAGEDICTIONARY_REQUEST))
  return await controller(routes.getLanguageDictionary)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETLANGUAGEDICTIONARY_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETLANGUAGEDICTIONARY_ERROR, error.message)))
}

const setLangStore = (data, type = 'LANG_CODE_OBJ') => {
  return ({
    type,
    data
  })
}

export const otherAction = {
  saveContactUsForm,
  getPage,
  getMetaDatas,
  getWebsiteSlider,
  getGoogleAnalyticTrackingCode,
  getLanguageDictionary,
  setLangStore
}