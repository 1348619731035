import { actionTypes } from '../actions/product.action'

const initialState = {
  error: null,
  isLoading: false,
  productsData: null,
  productRatingAndReviewData: null,
  addProductRatingAndReviewData: null,
  relatedProductData: null,
  productDetailData: null,
  productCategoryData: null,
  countryOriginData: null,
  brandsData: null,
  productFromProductGroupData: null,
  productBalanceData: null,
  orderedProductData: null,
  updateProductViewCountData: null,
  productsOfPromotionData: null,
  promotionsData: null
}

const product = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GETPRODUCTLIST_REQUEST: // getProductList
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRODUCTLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productsData: action.data
      }
    case actionTypes.GETPRODUCTLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPRODUCTRATINGANDREVIEW_REQUEST: // getProductRatingAndReview
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRODUCTRATINGANDREVIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productRatingAndReviewData: action.data
      }
    case actionTypes.GETPRODUCTRATINGANDREVIEW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.ADDPRODUCTRATINGANDREVIEW_REQUEST: // addProductRatingAndReview
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.ADDPRODUCTRATINGANDREVIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addProductRatingAndReviewData: action.data
      }
    case actionTypes.ADDPRODUCTRATINGANDREVIEW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETRELATEDPRODUCT_REQUEST: // getRelatedProduct
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETRELATEDPRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        relatedProductData: action.data
      }
    case actionTypes.GETRELATEDPRODUCT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPRODUCTDETAIL_REQUEST: // getProductDetail
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRODUCTDETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productDetailData: action.data
      }
    case actionTypes.GETPRODUCTDETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPRODUCTCATEGORY_REQUEST: // getProductCategory
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productCategoryData: action.data
      }
    case actionTypes.GETPRODUCTCATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETCOUNTRYORIGIN_REQUEST: // getCountryOrigin
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETCOUNTRYORIGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countryOriginData: action.data
      }
    case actionTypes.GETCOUNTRYORIGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETBRANDLIST_REQUEST: // getBrandList
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETBRANDLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        brandsData: action.data
      }
    case actionTypes.GETBRANDLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPRODUCTSFROMPRODUCTGROUP_REQUEST: // getProductsFromProductGroup
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRODUCTSFROMPRODUCTGROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productFromProductGroupData: action.data
      }
    case actionTypes.GETPRODUCTSFROMPRODUCTGROUP_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.CHECKPRODUCTBALANCE_REQUEST: // checkProductBalance
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.CHECKPRODUCTBALANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productBalanceData: action.data
      }
    case actionTypes.CHECKPRODUCTBALANCE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETORDEREDPRODUCT_REQUEST: // getOrderedProduct
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETORDEREDPRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderedProductData: action.data
      }
    case actionTypes.GETORDEREDPRODUCT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.UPDATEPRODUCTVIEWCOUNT_REQUEST: // updateProductViewCount
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.UPDATEPRODUCTVIEWCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateProductViewCountData: action.data
      }
    case actionTypes.UPDATEPRODUCTVIEWCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPRODUCTLISTOFPROMOTION_REQUEST: // getProductListOfPromotion
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPRODUCTLISTOFPROMOTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productsOfPromotionData: action.data
      }
    case actionTypes.GETPRODUCTLISTOFPROMOTION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case actionTypes.GETPROMOTIONLIST_REQUEST: // getPromotionList
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GETPROMOTIONLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        promotionsData: action.data
      }
    case actionTypes.GETPROMOTIONLIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default product