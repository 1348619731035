import React from 'react'

import { StyledTableBody, StyledTableRow, StyledTableCol } from '@core'

export const TableBodyCom = ({ bodyData, children, props }) => {
  return (
    <StyledTableBody {...props} >
      {bodyData}
      {children}
    </StyledTableBody>
  )
}

export const TrCom = ({ children, props }) => {
  return (
    <StyledTableRow {...props} >
      {children}
    </StyledTableRow>
  )
}

export const TdCom = ({ children, props }) => {
  return (
    <StyledTableCol {...props} >
      {children}
    </StyledTableCol>
  )
}