import { rgba } from 'polished'
import styled, { css } from 'styled-components'

const StyledCard = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: 1px 1px 10px ${props => rgba(props.theme.color.dark, 0.15)};
  background-color: ${props => props.bgColor || props.theme.color.card};

  ${({ type }) => type === 'product' && css`
    &:hover {
      box-shadow: ${({ theme }) => rgba(theme.color.dark, .1)} 0px 4px 8px 0px, ${({ theme }) => rgba(theme.color.dark, .1)} 0px 4px 8px 0px;
      ${props => props.type !== 'simple' && `transform: translateY(-8px);`};
      transition: ease-in-out .2s;
    }
  `}


  a{
    text-decoration: none;
  }

  .rating{
    padding: 10px 15px 0;
    .star{
      margin-right: 5px;
    }
  }
`

const StyledCardTitle = styled.div`
  padding: 15px;
`

const StyledCardContent = styled.div`
  padding: 15px;
  width: 100%;

  .card-content-wrap{
    ${({ type }) => type === 'product' && `min-height: 110px;`};

    p{
      margin-bottom: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .price-wrap{
      display: flex;
      flex-direction: row;
      align-items: center;

      .del{
        margin-right: 5px;
      }
    }
  }

  .rating-wrap{
    display: flex;
    justify-content: space-between;

    svg{
      color: ${props => props.theme.color.primary}
    }
  }
`

const StyledCardFooter = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export {
  StyledCard,
  StyledCardTitle,
  StyledCardContent,
  StyledCardFooter
}