import React from 'react'
import { AiFillFacebook, AiFillTwitterSquare } from 'react-icons/ai'
import { FaViber } from 'react-icons/fa'

import { StyledSocialIcon } from '@core'

export const SocialIconCom = props => {
  const { data } = props

  let dataSource = data.map(x => (
    {
      ...x,
      icon: x.name === 'facebook' ?
        <AiFillFacebook size={30} /> :
        x.name === 'viber' ? <FaViber size={30} /> :
          <AiFillTwitterSquare size={30} />
    }
  ))

  return (
    <StyledSocialIcon {...props} >
      {
        dataSource?.length > 0 &&
        dataSource.map((x, i) => (
          <a key={i} href={x.name === 'viber' ? `tel:${x.link}` : x.link} target='_blank'>
            {x.icon}
          </a>
        ))
      }
    </StyledSocialIcon>
  )
}

SocialIconCom.defaultProps = {
  data: []
}