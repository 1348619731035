import { controller, routes, routeFilter } from '@controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  // addUserWishList
  ADDUSERWISHLIST_REQUEST: 'ADDUSERWISHLIST_REQUEST',
  ADDUSERWISHLIST_ERROR: 'ADDUSERWISHLIST_ERROR',
  ADDUSERWISHLIST_SUCCESS: 'ADDUSERWISHLIST_SUCCESS',

  // removeProductFromWishList
  REMOVEPRODUCTFROMWISHLIST_REQUEST: 'REMOVEPRODUCTFROMWISHLIST_REQUEST',
  REMOVEPRODUCTFROMWISHLIST_ERROR: 'REMOVEPRODUCTFROMWISHLIST_ERROR',
  REMOVEPRODUCTFROMWISHLIST_SUCCESS: 'REMOVEPRODUCTFROMWISHLIST_SUCCESS',

  // getAllWishList
  GETALLWISHLIST_REQUEST: 'GETALLWISHLIST_REQUEST',
  GETALLWISHLIST_ERROR: 'GETALLWISHLIST_ERROR',
  GETALLWISHLIST_SUCCESS: 'GETALLWISHLIST_SUCCESS',

  // existWishlistOrNot
  EXISTWISHLISTORNOT_REQUEST: 'EXISTWISHLISTORNOT_REQUEST',
  EXISTWISHLISTORNOT_ERROR: 'EXISTWISHLISTORNOT_ERROR',
  EXISTWISHLISTORNOT_SUCCESS: 'EXISTWISHLISTORNOT_SUCCESS',
}

const addUserWishList = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.ADDUSERWISHLIST_REQUEST))
  return await controller(routes.addUserWishList, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.ADDUSERWISHLIST_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.ADDUSERWISHLIST_ERROR, error.message)))
}

const removeProductFromWishList = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.REMOVEPRODUCTFROMWISHLIST_REQUEST))
  return await controller(routes.removeProductFromWishList, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.REMOVEPRODUCTFROMWISHLIST_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.REMOVEPRODUCTFROMWISHLIST_ERROR, error.message)))
}

//? Params
// lang, user_id
const getAllWishList = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETALLWISHLIST_REQUEST))
  return await controller(`${routes.getAllWishList}${routeFilter(data)}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETALLWISHLIST_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETALLWISHLIST_ERROR, error.message)))
}

//? Params
// user_id, product_id, variant_id
const existsWishListOrNot = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.EXISTWISHLISTORNOT_REQUEST))
  return await controller(`${routes.existsWishListOrNot}${routeFilter(data)}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.EXISTWISHLISTORNOT_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.EXISTWISHLISTORNOT_ERROR, error.message)))
}

export const wishListAction = {
  addUserWishList,
  removeProductFromWishList,
  getAllWishList,
  existsWishListOrNot
}