import React from 'react'

import { StyledImageContainer } from '@core'
import { CustomPlaceholder } from './imgplaceholder'

export const ImageCom = ({ img, children, ...props }) => {
  return (
    <StyledImageContainer img={img}>
      <CustomPlaceholder {...props} style={{ opacity: img ? 0 : 1 }} />
      {children}
    </StyledImageContainer>
  )
}

ImageCom.defaultProps = {
  backgroundColor: '#eee',
  textColor: '#000',
  text: 'Standard UI/UX'
}