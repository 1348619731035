import React from 'react'

import { StyledContainer } from '@core'

export const ContainerCom = props => {
  const { children } = props
  return (
    <StyledContainer {...props}>
      {children}
    </StyledContainer>
  )
}