import { controller, routes, routeFilter } from '@controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  // getPaymentList
  GETPAYMENTLIST_REQUEST: 'GETPAYMENTLIST_REQUEST',
  GETPAYMENTLIST_ERROR: 'GETPAYMENTLIST_ERROR',
  GETPAYMENTLIST_SUCCESS: 'GETPAYMENTLIST_SUCCESS',

  // payNow
  PAYNOW_REQUEST: 'PAYNOW_REQUEST',
  PAYNOW_ERROR: 'PAYNOW_ERROR',
  PAYNOW_SUCCESS: 'PAYNOW_SUCCESS',

  // getShippingAddress
  GETSHIPPINGADDRESS_REQUEST: 'GETSHIPPINGADDRESS_REQUEST',
  GETSHIPPINGADDRESS_ERROR: 'GETSHIPPINGADDRESS_ERROR',
  GETSHIPPINGADDRESS_SUCCESS: 'GETSHIPPINGADDRESS_SUCCESS',

  // createShippingAddress
  CREATESHIPPINGADDRESS_REQUEST: 'CREATESHIPPINGADDRESS_REQUEST',
  CREATESHIPPINGADDRESS_ERROR: 'CREATESHIPPINGADDRESS_ERROR',
  CREATESHIPPINGADDRESS_SUCCESS: 'CREATESHIPPINGADDRESS_SUCCESS',

  // updateShippingAddress
  UPDATESHIPPINGADDRESS_REQUEST: 'UPDATESHIPPINGADDRESS_REQUEST',
  UPDATESHIPPINGADDRESS_ERROR: 'UPDATESHIPPINGADDRESS_ERROR',
  UPDATESHIPPINGADDRESS_SUCCESS: 'UPDATESHIPPINGADDRESS_SUCCESS',

  // getDeliveryFee
  GETDELIVERYFEE_REQUEST: 'GETDELIVERYFEE_REQUEST',
  GETDELIVERYFEE_ERROR: 'GETDELIVERYFEE_ERROR',
  GETDELIVERYFEE_SUCCESS: 'GETDELIVERYFEE_SUCCESS',

  // getShippingLocation
  GETSHIPPINGLOCATION_REQUEST: 'GETSHIPPINGLOCATION_REQUEST',
  GETSHIPPINGLOCATION_ERROR: 'GETSHIPPINGLOCATION_ERROR',
  GETSHIPPINGLOCATION_SUCCESS: 'GETSHIPPINGLOCATION_SUCCESS',

  // saveShippingAddressInfo
  SAVESHIPPINGADDRESSINFO_REQUEST: 'SAVESHIPPINGADDRESSINFO_REQUEST',
  SAVESHIPPINGADDRESSINFO_ERROR: 'SAVESHIPPINGADDRESSINFO_ERROR',
  SAVESHIPPINGADDRESSINFO_SUCCESS: 'SAVESHIPPINGADDRESSINFO_SUCCESS',

  // saveInvoiceAddressInfo
  SAVEINVOICEADDRESSINFO_REQUEST: 'SAVEINVOICEADDRESSINFO_REQUEST',
  SAVEINVOICEADDRESSINFO_ERROR: 'SAVEINVOICEADDRESSINFO_ERROR',
  SAVEINVOICEADDRESSINFO_SUCCESS: 'SAVEINVOICEADDRESSINFO_SUCCESS',
}

const getPaymentList = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETPAYMENTLIST_REQUEST))
  return await controller(routes.getPaymentList)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETPAYMENTLIST_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETPAYMENTLIST_ERROR, error.message)))
}

const payNow = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.PAYNOW_REQUEST))
  return await controller(routes.payNow, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.PAYNOW_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.PAYNOW_ERROR, error.message)))
}

const getShippingAddress = id => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETSHIPPINGADDRESS_REQUEST))
  return await controller(`${routes.getShippingAddress}/${id}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETSHIPPINGADDRESS_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETSHIPPINGADDRESS_ERROR, error.message)))
}

const createShippingAddress = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.CREATESHIPPINGADDRESS_REQUEST))
  return await controller(routes.createShippingAddress, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.CREATESHIPPINGADDRESS_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.CREATESHIPPINGADDRESS_ERROR, error.message)))
}

const updateShippingAddress = (id, data) => async dispatch => {
  dispatch(ToDoRequest(actionTypes.UPDATESHIPPINGADDRESS_REQUEST))
  return await controller(`${routes.updateShippingAddress}/${id}`, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.UPDATESHIPPINGADDRESS_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.UPDATESHIPPINGADDRESS_ERROR, error.message)))
}

const getDeliveryFee = township_id => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETDELIVERYFEE_REQUEST))
  return await controller(`${routes.getDeliveryFee}?township_id=${township_id}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETDELIVERYFEE_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETDELIVERYFEE_ERROR, error.message)))
}

const getShippingLocation = country_code => async dispatch => {
  dispatch(ToDoRequest(actionTypes.GETSHIPPINGLOCATION_REQUEST))
  return await controller(`${routes.getShippingLocation}/${country_code}`)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.GETSHIPPINGLOCATION_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.GETSHIPPINGLOCATION_ERROR, error.message)))
}

const saveShippingAddressInfo = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.SAVESHIPPINGADDRESSINFO_REQUEST))
  return await controller(routes.saveShippingAddressInfo, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.SAVESHIPPINGADDRESSINFO_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.SAVESHIPPINGADDRESSINFO_ERROR, error.message)))
}

const saveInvoiceAddressInfo = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.SAVEINVOICEADDRESSINFO_REQUEST))
  return await controller(routes.saveInvoiceAddressInfo, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.SAVEINVOICEADDRESSINFO_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.SAVEINVOICEADDRESSINFO_ERROR, error.message)))
}

export const checkOutAction = {
  getPaymentList,
  payNow,
  getShippingAddress,
  createShippingAddress,
  updateShippingAddress,
  getDeliveryFee,
  getShippingLocation,
  saveShippingAddressInfo,
  saveInvoiceAddressInfo
}