import styled from 'styled-components'
import Pagination from 'rc-pagination'
import { rgba } from 'polished'

const imgUrl = {
  leftArrow: require('@asset/icon/left-arrow.svg').default,
  rightArrow: require('@asset/icon/right-arrow.svg').default
}

export const StyledTable = styled.table`
  width: 100%;
  border: ${({ theme }) => theme.inputGeneral.border} ${({ theme }) => theme.color.light};
  border-collapse: collapse;
  border-radius: ${({ theme }) => theme.inputGeneral.borderRadius}px;
  border-style: hidden;
  box-shadow: 1px 1px 10px ${props => rgba(props.theme.color.dark, 0.15)};
  background-color: ${props => props.bgColor || props.theme.color.card};
  overflow: hidden;
`

export const StyledTableHeader = styled.thead`
  
`

export const StyledTableHeaderCol = styled.th`
  padding: 15px 15px;
  background-color: ${({ theme }) => theme.color.grey}
`

export const StyledTableBody = styled.tbody`

`

export const StyledTableRow = styled.tr`
  border-bottom: ${({ theme }) => theme.inputGeneral.border} ${({ theme }) => theme.color.grey};
`

export const StyledTableCol = styled.td`
  padding: 10px 15px;
`

export const StyledTableFoot = styled.tfoot`
  
`

export const StyledPagination = styled(Pagination)`
  margin: 15px 0;
  &.rc-pagination {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    li {
      cursor: pointer;
      &:focus {
        outline: none;
      }
      a, button {
        text-decoration: none;
        position: relative;
        display: block;
        padding: .5rem;
        margin: 0 3px;
        line-height: 1.25;
        background-color: transparent;
        border: 1px solid transparent;
        color: ${({ theme }) => theme.color.grey};
        font-weight: 500;
        transition: all .2s;
        border-radius: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: ${({ theme }) => theme.color.primary};
          color: ${({ theme }) => theme.color.white};
        }
      }
      &.rc-pagination-disabled {
        a, button {
          background-color: transparent;
          cursor: default;
        }
      }
      button {
        &:focus {
          outline: none;
        }
        &:before {
          content: '';
          width: 12px;
          height: 12px;
          display: inline-block;
        }
      }
      &.rc-pagination-item-active {
        a {
          background: ${({ theme }) => theme.color.primary};
          color: ${({ theme }) => theme.color.white};
        }
      }
      &.rc-pagination-prev {
        button {
          margin: 0;
          margin-right: 3px;
          border-radius: 10px;
          &:before {                        
            background: url(${imgUrl.leftArrow}) no-repeat center/cover;
          }
          &:hover {
            &:before {                        
              background: url(${imgUrl.leftArrow}) no-repeat center/cover;
            }
          }
        }
      }
      &.rc-pagination-prev.rc-pagination-disabled {
        button {
          &:hover {
            &:before {                        
              background: url(${imgUrl.leftArrow}) no-repeat center/cover;
            }
          }
          &:before {
            opacity: .4;
          }
        }
      }
      &.rc-pagination-next.rc-pagination-disabled {
        button {
          &:hover {
            &:before {                        
              background: url(${imgUrl.rightArrow}) no-repeat center/cover;
              opacity: .5;
            }
          }
          &:before {
            opacity: .4;
          }
        }
      }
      &.rc-pagination-next {
        button {
          border-radius: 10px;
          margin: 0;
          margin-left: 3px;
          &:before {
            background: url(${imgUrl.rightArrow}) no-repeat center/cover;
          }
          &:hover {
            &:before {                        
              background: url(${imgUrl.rightArrow}) no-repeat center/cover;
            }
          }
        }
      }
      &.rc-pagination-jump-prev, &.rc-pagination-jump-next {
        button {
          &:before {
            content: '...';
          }
        }
      }
    }
  }
`