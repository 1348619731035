import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { emit } from '@actions'
import { StyledPagination } from '@core'

const queryString = require('query-string')
export const PaginationCom = props => {
  const { history, type } = props
  const { filterData } = useSelector(state => state.emit)
  const dispatch = useDispatch()

  useEffect(() => {
    if (type === 'url') {
      let obj = queryString.parse(history.location.search)
      let tmp = obj?.offset ? { ...obj } : { ...obj, offset: 1 }
      dispatch(emit.setEmit('FILTERDATA', tmp))
    }
  }, [dispatch])

  useEffect(() => {
    if (type === 'url') {
      filterData && history.push({
        pathname: window.location.pathname,
        search: queryString.stringify(filterData)
      })
    }
  }, [filterData?.offset])

  const onHandlePaginate = (key, value) => {
    console.log(`heele`,)
    dispatch(emit.setEmit('FILTERDATA', {
      ...filterData,
      [key]: value
    }))
  }

  return (
    <StyledPagination
      showLessItems={true}
      showTitle={false}
      onChange={data => type === 'url' ? onHandlePaginate('offset', data) : props.onChangePaginate(data)}
      current={type === 'url' ? +filterData?.offset : props.current}
      {...props}
    />
  )
}

PaginationCom.defaultProps = {
  type: 'simple'
}