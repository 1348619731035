import React from 'react'

import { StyledPrimaryFooter } from '@core'

export const PrimaryFooterCom = props => {

  return (
    <StyledPrimaryFooter {...props} >
      {props.children}
    </StyledPrimaryFooter>
  )
}