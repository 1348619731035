import styled from 'styled-components'
import { media } from '../../attribute'

export const StyledInfoContainer = styled.div`
  padding: 30px 50px;
  display: flex;
  flex-direction: column;

  .first-col{
    display: flex;
    flex-direction: column;

    .img-wrap{
      img{
        width: 100%;
        height: auto;
        
        @media screen and (min-width: ${media.md}px) {
          width: 445px;
          height: auto;
        }
      }
    }
  }

  .sec-col{
    display: flex;
    flex-direction: column
  }
`