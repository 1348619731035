import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineSearch } from 'react-icons/ai'

import { emit } from '@actions'
import { StyledSearch } from '@core'
import { InputCom } from '../form'
import { ButtonCom } from '../button'

const queryString = require('query-string')
export const SearchCom = props => {
  const { history } = props
  const dispatch = useDispatch()
  const { filterData } = useSelector(state => state.emit)

  useEffect(() => {
    let obj = queryString.parse(history.location.search)
    dispatch(emit.setEmit('FILTERDATA', { ...obj }))
  }, [dispatch])

  const handleChange = (key, value) => {
    dispatch(emit.setEmit('FILTERDATA', {
      ...filterData,
      [key]: value
    }))
  }

  const handleSearch = () => {
    handleUrlFilter()
  }

  const handleUrlFilter = () => {
    filterData && history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(filterData)
    })
  }

  return (
    <StyledSearch {...props} >
      <InputCom
        type='text'
        value={filterData?.key}
        {...props}
        onChange={e => handleChange('key', e.target.value)}
        onKeyPress={e => e.key === 'Enter' && handleSearch()}
      />
      <ButtonCom SVGL={<AiOutlineSearch />} className='btn' onClick={() => handleSearch()} />
    </StyledSearch>
  )
}

SearchCom.defaultProps = {

}