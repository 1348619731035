import React from 'react'

import { StyledLabel, StyledInputGroup } from '@core'

export const FieldWrapper = ({ label, children, ...props }) => {

  return (
    <StyledInputGroup {...props}>
      {props.type !== 'checkbox' && <StyledLabel>
        {label}
      </StyledLabel>}
      {children}
    </StyledInputGroup>
  )
}