import { controller, routes } from '@controller'
import { ToDoRequest, ToDoSuccess, ToDoError } from './typehandle.action'

export const actionTypes = {
  // loginWithPhoneAndPass
  LOGINWITHPHONEANDPASS_REQUEST: 'LOGINWITHPHONEANDPASS_REQUEST',
  LOGINWITHPHONEANDPASS_ERROR: 'LOGINWITHPHONEANDPASS_ERROR',
  LOGINWITHPHONEANDPASS_SUCCESS: 'LOGINWITHPHONEANDPASS_SUCCESS',

  // loginWithFacebook
  LOGINWITHFACEBOOK_REQUEST: 'LOGINWITHFACEBOOK_REQUEST',
  LOGINWITHFACEBOOK_ERROR: 'LOGINWITHFACEBOOK_ERROR',
  LOGINWITHFACEBOOK_SUCCESS: 'LOGINWITHFACEBOOK_SUCCESS',

  // logOUt
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
}

const loginWithPhoneAndPass = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.LOGINWITHPHONEANDPASS_REQUEST))
  return await controller(routes.loginWithPhoneAndPass, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.LOGINWITHPHONEANDPASS_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.LOGINWITHPHONEANDPASS_ERROR, error.message)))
}

const loginWithFacebook = data => async dispatch => {
  dispatch(ToDoRequest(actionTypes.LOGINWITHFACEBOOK_REQUEST))
  return await controller(routes.loginWithFacebook, data)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.LOGINWITHFACEBOOK_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.LOGINWITHFACEBOOK_ERROR, error.message)))
}

const logOut = () => async dispatch => {
  dispatch(ToDoRequest(actionTypes.LOGOUT_REQUEST))
  return await controller(routes.logOut)
    .then(res => {
      if (res.data) {
        dispatch(ToDoSuccess(actionTypes.LOGOUT_SUCCESS, res.data))
      } else {
        console.log(res.data)
      }
    })
    .catch(error => dispatch(ToDoError(actionTypes.LOGOUT_ERROR, error.message)))
}

export const authAction = {
  loginWithPhoneAndPass,
  loginWithFacebook,
  logOut
}